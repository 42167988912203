// PairedItems.js
import React, { useState } from 'react';
import axios from 'axios';
import './PairedItems.css'


/**
 * Functional component for displaying and generating reports for commonly paired items.
 * @returns {JSX.Element} JSX element representing the PairedItems component.
 */
const PairedItems = () => {
  /**
   * State for managing date and time range selection.
   * @type {Object}
   * @property {string} startDate - The selected start date.
   * @property {string} endDate - The selected end date.
   * @property {string} startTime - The selected start time.
   * @property {string} endTime - The selected end time.
   */
  const [dateTimeRange, setDateTimeRange] = useState({
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  });


  /**
   * State for managing loading status during API requests.
   * @type {boolean}
   */
  const [loading, setLoading] = useState(false);

  /**
   * State for tracking whether a report has been generated.
   * @type {boolean}
   */
  const [reportGenerated, setReportGenerated] = useState(false);
  
  
  /**
   * State for storing the results of paired items and their order counts.
   * @type {Object}
   * @property {string[]} pairedMenuCombinations - Array of paired menu combinations.
   * @property {number[]} orderCount - Array of order counts corresponding to paired combinations.
   */
  const [pairedItemsResult, setPairedItemsResult] = useState({
    pairedMenuCombinations: [],
    orderCount: [],
  });


  /**
   * Generates all possible combinations of items from the given array.
   * @param {string[]} names - Array of item names.
   * @returns {string[]} Array of paired combinations.
   */
  const generatePairedCombinations = (names) => {
    const pairedCombinations = [];
    for (let i = 0; i < names.length - 1; i++) {
      for (let j = i + 1; j < names.length; j++) {
        pairedCombinations.push(`${names[i]}, ${names[j]}`);
      }
    }
    return pairedCombinations;
  };

    /**
   * Resets the report generation status when the date is changed.
   */
  const handleDateChange = () => {
    setReportGenerated(false);
  };


  /**
   * Handles the form submission to generate paired item reports.
   * @param {Event} e - The form submission event.
   */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setReportGenerated(false);

    const { startDate, endDate, startTime, endTime } = dateTimeRange;

    if (!startDate || !endDate || !startTime || !endTime) {
      window.alert('Please fill in all date and time');
      return;
    }

    try {
      setLoading(true);

      const startDateTime = `${startDate}T${startTime}:00`;
      const endDateTime = `${endDate}T${endTime}:00`;

      const response1 = await axios.get('https://yifang-server-2xpp.onrender.com/api/sold-drinks', {
        params: { startDate, endDate, startTime, endTime },
      });

      const menuNames = response1.data.drinkSales.map(entry => entry.menu_item);

      const pairedMenuCombinations = generatePairedCombinations(menuNames);

      const pairedItemsPromises = pairedMenuCombinations.map(async (pair) => {
        const [drink1, drink2] = pair.split(',').map(item => item.trim()); // Trim each item
        console.log(drink1, drink2);

        try {
          const response = await axios.get('https://yifang-server-2xpp.onrender.com/paired-items', {
            params: {
              drink1,
              drink2,
              startDate,
              endDate,
              startTime,
              endTime,
            },
          });

          // Process the response as needed
          console.log(response.data);

          setPairedItemsResult((prevResult) => ({
            pairedMenuCombinations: [...prevResult.pairedMenuCombinations, pair],
            orderCount: [...prevResult.orderCount, response.data],
          }));

        } catch (error) {
          console.error('Error fetching paired items:', error);
        }
      });

      // Wait for all promises to resolve
      await Promise.all(pairedItemsPromises);

      setReportGenerated(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }


    //console.log(pairedItemsResult.orderCount[0].orderCount);
  };

  return (
    <div>
      <h2>Commonly Paired Items</h2>
      <form onSubmit={handleFormSubmit}>
      <div className="form-row">
          <label htmlFor="startDateTime" className="form-label">
            Start Date and Time:
          </label>
          <input
            id="startDateTime"
            className="form-input"
            type="datetime-local"
            value={dateTimeRange.startDate + 'T' + dateTimeRange.startTime}
            onChange={(e) => {
              const dateTime = e.target.value.split('T');
              setDateTimeRange(prevState => ({ ...prevState, startDate: dateTime[0], startTime: dateTime[1] }));
              handleDateChange();
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="endDateTime" className="form-label">
            End Date and Time:
          </label>
          <input
            id="endDateTime"
            className="form-input"
            type="datetime-local"
            value={dateTimeRange.endDate + 'T' + dateTimeRange.endTime}
            onChange={(e) => {
              const dateTime = e.target.value.split('T');
              setDateTimeRange(prevState => ({ ...prevState, endDate: dateTime[0], endTime: dateTime[1] }));
              handleDateChange();
            }}
          />
        </div>

        {loading && <p>Loading...</p>}
        

        <button className="paied-Report-Button" type="submit">
          Generate Report
        </button>
      </form>


      {pairedItemsResult.pairedMenuCombinations.length > 0 && reportGenerated && (
        <div>
          <h3>Paired Items Result</h3>
          {pairedItemsResult.pairedMenuCombinations
            .map((pair, index) => ({
              pair,
              orderCount: pairedItemsResult.orderCount[index].orderCount,
            }))
            .sort((a, b) => b.orderCount - a.orderCount)
            
            .map((result, index) => (
              <div key={index}>
                <p>Combination: {result.pair.replace(/(?<=\w)(?=[A-Z])/g, ', ')}</p>
                <p>Order Count: {result.orderCount}</p>
              </div>
            ))
          }
        </div>
      )}

    </div>
  );
};

export default PairedItems;
