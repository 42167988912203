import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './customerCheckout.css'; // Create a corresponding CSS file for styling
import Modal from 'react-modal';
import ProductModal from './cashierComponents/ProductModal';
import { v4 as uuidv4 } from 'uuid';
//import PopupModal  from './drinkPopup';
import MainLayout from './cashierComponents/layouts/MainLayout';
import { toast } from 'react-toastify';

function CustomerCheckoutPage() {
    const navigate = useNavigate();

    const [cart, setCart] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedToppings, setSelectedToppings] = useState([]);
    const [originalProduct, setOriginalProduct] = useState(null);
    const [orderAmountMap, setOrderAmountMap] = useState({});
    const [servingSizeMap, setServingSizeMap] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isLoading, setIsLoading] = useState(false);



    const toastOptions = {
        autoClose: 400,
        pauseOnHover: true,
    }

    /**
 * Updates the cart with new items and saves it to local storage.
 * @param {Array} newCart - The new array of items to be set in the cart.
 */
    const updateCart = (newCart) => {
        setCart(newCart);
        localStorage.setItem('CustomerCart', JSON.stringify(newCart));
    };

    /**
 * Clears all items from the cart and resets the order amount map.
 */
    const clearCart = () => {
        updateCart([]);
        const resetOrderAmounts = Object.keys(orderAmountMap).reduce((acc, key) => {
            acc[key] = 0;
            return acc;
        }, {});
        setOrderAmountMap(resetOrderAmounts);
    };

    /**
 * Removes a specific product from the cart.
 * @param {Object} product - The product to be removed.
 */
    const removeProduct = async (product) => {
        const newCart = cart.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
        removeToppingAndIngredient(product);
        updateCart(newCart);
    }

    /**
 * Updates order amount map by removing the quantities of toppings and ingredients.
 * @param {Object} productWithOptions - The product from which toppings and ingredients are to be removed.
 */
    const removeToppingAndIngredient = (productWithOptions) => {
        const updatedOrderAmounts = { ...orderAmountMap };
        const toppings = productWithOptions.toppings;
        const ingredients = productWithOptions.ingredients;

        toppings.forEach(topping => {
            const servingSize = servingSizeMap[topping] || 0;
            updatedOrderAmounts[topping] = Math.max((updatedOrderAmounts[topping] || 0) - servingSize, 0);
        });

        ingredients.forEach(ingredient => {
            const servingSize = servingSizeMap[ingredient] || 0;
            updatedOrderAmounts[ingredient] = Math.max((updatedOrderAmounts[ingredient] || 0) - servingSize, 0);
        });
        setOrderAmountMap(updatedOrderAmounts);
    };

    /**
 * Updates the order amount map by adding the quantities of toppings and ingredients.
 * @param {Object} productWithOptions - The product to which toppings and ingredients are to be added.
 */
    const addIngredientAndToppingAmounts = (productWithOptions) => {
        const updatedOrderAmounts = { ...orderAmountMap };
        if (!productWithOptions.isEditing) {
            const ingredients = productWithOptions.ingredients;
            const toppings = productWithOptions.toppings;

            ingredients.forEach(ingredient => {
                const servingSize = servingSizeMap[ingredient] || 0;
                updatedOrderAmounts[ingredient] = (updatedOrderAmounts[ingredient] || 0) + servingSize;
            });

            toppings.forEach(topping => {
                const servingSize = servingSizeMap[topping] || 0;
                updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) + servingSize;
            });
        }
        else {
            const originalToppings = originalProduct.toppings;
            const newToppings = productWithOptions.toppings;

            originalToppings.forEach(topping => {
                if (!newToppings.includes(topping)) {
                    const servingSize = servingSizeMap[topping] || 0;
                    updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) + servingSize;
                }
            });
            setOriginalProduct(null);
        }
        setOrderAmountMap(updatedOrderAmounts);
    };

    useEffect(() => {
        let newTotalAmount = 0;
        cart.forEach(cartItem => {
            newTotalAmount += parseFloat(cartItem.totalAmount)
        });
        setTotalAmount(newTotalAmount.toFixed(2));
    }, [cart]);

    useEffect(() => {
        const savedCart = localStorage.getItem('CustomerCart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    });

    /**
 * Edits an existing product in the cart.
 * @param {Object} product - The product to be edited.
 */
    const editProductInCart = (product) => {
        const existingProductIndex = cart.findIndex((cartItem) => cartItem.cartItemId === product.cartItemId);

        if (existingProductIndex > -1) {
            const newCart = [...cart];
            const existingProduct = newCart[existingProductIndex];

            const newTotalAmount = (parseFloat(product.price) + (product.toppings.length * 0.75)).toFixed(2);
            newCart[existingProductIndex] = {
                ...existingProduct,
                totalAmount: newTotalAmount,
                toppings: product.toppings,
                icelevel: product.icelevel,
                sugarlevel: product.sugarlevel
            };

            updateCart(newCart);

        }
        else {
            console.error('Product to edit not found in cart');
        }

    };

    /**
 * Prepares the selected product for editing and opens the modal.
 * @param {Object} product - The product to be edited.
 */
    const editProduct = (product) => {

        setOriginalProduct(product);
        setSelectedProduct({ ...product, isEditing: true });
        setSelectedToppings(product.toppings);
        setModalShow(true);
    };

    /**
 * Adds a product with options (like toppings) to the cart.
 * @param {Object} product - The product to be added to the cart.
 */
    const addProductWithOptionsToCart = (product) => {
        const newProduct = {
            ...product,
            cartItemId: uuidv4(),
        };

        updateCart([...cart, newProduct]);
    };

    /**
 * Handles the modal confirmation, either editing or adding a product with options.
 * @param {Object} productWithOptions - The product with selected options.
 */
    const onModalConfirm = async (productWithOptions) => {
        let allToppingsAvailable = await isEnoughInventoryForProductTopping(productWithOptions);

        if (allToppingsAvailable) {
            addIngredientAndToppingAmounts(productWithOptions);
            if (productWithOptions.isEditing) {
                editProductInCart(productWithOptions);
            }
            else {
                addProductWithOptionsToCart(productWithOptions);
            }
        }
        else {
            toast.error("Not enough inventory for topping chosen", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
        setModalShow(false);
    };

    /**
 * Checks if there is enough inventory for all toppings of a product.
 * @param {Object} productWithOptions - Product with options such as toppings.
 * @returns {Promise<boolean>} - True if enough inventory, false otherwise.
 */
    const isEnoughInventoryForProductTopping = async (productWithOptions) => {
        for (const topping of productWithOptions.toppings) {
            let requiredQuantity = (orderAmountMap[topping] || 0) + (servingSizeMap[topping] || 0);
            if (productWithOptions.isEditing) {
                const originalToppings = originalProduct.toppings;

                if (originalToppings.includes(topping)) {
                    requiredQuantity -= servingSizeMap[topping];
                }
            }
            const availableQuantity = await getIngredientQuantity(topping);

            if (requiredQuantity > availableQuantity) {
                return false;
            }
        }
        return true;
    };

    /**
 * Determines the number of decimal places in a number.
 * @param {number} num - The number to check.
 * @returns {number} - Number of decimal places.
 */
    const getDecimalPlaces = (num) => {
        if (!isNaN(num) && Math.floor(num) !== num) {
            return num.toString().split(".")[1].length;
        }
        return 0;
    };

    /**
* Updates the quantity of an inventory item in the database.
* @param {string} itemName - The name of the item to update.
* @param {number} newQuantity - The new quantity to set.
* @returns {Promise<boolean>} - True if update is successful, false otherwise.
*/
    const updateInventoryItem = async (itemName, newQuantity) => {
        try {
            const url = 'https://yifang-server-2xpp.onrender.com/ManagerPageTest_Update/${encodeURIComponent(itemName)}/${newQuantity}';
            console.log('Updating inventory with URL: ${url}');

            const response = await axios.put(url);

            if (response.data.success) {
                return true;
            } else {
                throw new Error(`API responded with success: false for ${itemName}`);
            }
        } catch (error) {
            console.error(`Error updating inventory for ${itemName}:`, error);
            return false;
        }
    };

    /**
 * Rounds a number to the maximum decimal places of two reference numbers.
 * @param {number} numToRound - The number to round.
 * @param {number} referenceNum1 - The first reference number.
 * @param {number} referenceNum2 - The second reference number.
 * @returns {number} - The rounded number.
 */
    const roundToMaxDecimalPlaces = (numToRound, referenceNum1, referenceNum2) => {
        const decimalPlaces1 = getDecimalPlaces(referenceNum1);
        const decimalPlaces2 = getDecimalPlaces(referenceNum2);
        const maxDecimalPlaces = Math.max(decimalPlaces1, decimalPlaces2);

        return parseFloat(numToRound.toFixed(maxDecimalPlaces));
    };

    /**
 * Fetches the quantity of a specific ingredient from the inventory.
 * @param {string} ingredientName - Name of the ingredient.
 * @returns {Promise<number>} - The quantity of the ingredient.
 */
    const getIngredientQuantity = async (ingredientName) => {
        try {
            const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/inventory/quantity/${encodeURIComponent(ingredientName)}`);
            if (response.status === 404) {
                console.log("nothing found in database for: ", ingredientName);
                return -1;
            }
            else if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data.quantity;
        } catch (error) {
            return 0;
        }
    };

    /**
 * Handles the payment process based on the selected payment method.
 * @param {string} paymentMethod - The chosen method of payment (e.g., 'cash').
 */
    const handlePayment = async (paymentMethod) => {
        if (customerName === '') {
            toast.error("No customer name given", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!(cart.length > 0)) {
            toast.error("No items in cart", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (paymentMethod === 'cash') {
            // if method is cash, I want a popup to ask for cash amount and return change, then once that popup is closed, it proceeds
            const cashReceived = prompt('Enter cash amount received');
            if (cashReceived !== null) {
                const change = cashReceived - totalAmount;
                if (change >= 0) {
                    alert(`Change to return: $${change.toFixed(2)}`);
                    await processOrder(paymentMethod);
                } else {
                    toast.error("Insufficient cash received", {
                        // ... toast options
                    });
                }
            }
        } else {
            await processOrder(paymentMethod);
        }

    };

    /**
 * Processes the order, sends it to the server, and updates the inventory.
 * @param {string} paymentMethod - The payment method used for the order.
 */
    const processOrder = async (paymentMethod) => {
        const drinks = cart.map(item => item.name);
        const totalPrice = totalAmount;
        try {
            const orderResponse = await fetch('https://yifang-server-2xpp.onrender.com/create-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    customerName,
                    drinks,
                    paymentMethod,
                    totalPrice
                })
            });

            if (!orderResponse.ok) {
                throw new Error('Failed to create ordre');
            }

            const orderResult = await orderResponse.json();

            for (const [itemName, usedQuantity] of Object.entries(orderAmountMap)) {
                if (usedQuantity > 0) {
                    const newQuantity = await calculateNewInventoryQuantity(itemName, usedQuantity);
                    const roundedQuantity = roundToMaxDecimalPlaces(newQuantity, usedQuantity, servingSizeMap[itemName]);
                    const updateSuccess = await updateInventoryItem(itemName, roundedQuantity);
                    if (!updateSuccess) {
                        throw new Error('Failed to update inventory')
                    }
                }
            }
            toast(`Order Successfully Sent!`, toastOptions);
            setCustomerName('');
            setPaymentMethod('');
            clearCart();
        } catch (error) {

        }
    }

    /**
 * Calculates the new inventory quantity for an item after usage.
 * @param {string} itemName - The name of the inventory item.
 * @param {number} usedQuantity - The quantity of the item used.
 * @returns {Promise<number>} - The new inventory quantity after deduction.
 */
    const calculateNewInventoryQuantity = async (itemName, usedQuantity) => {
        // Fetch current quantity from inventory
        // Implement fetching current inventory quantity logic here
        const currentQuantity = await getIngredientQuantity(itemName);
        return Math.max(currentQuantity - usedQuantity, 0); // Ensure the quantity doesn't go below 0
    };

    /**
 * Fetches serving sizes and initializes the order amount map.
 */
    const fetchServingSizesAndInitializeOrders = async () => {
        try {
            const response = await fetch('https://yifang-server-2xpp.onrender.com/api/inventory/serving-sizes');
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setServingSizeMap(data);

            // Initialize orderAmountMap with the same keys, values set to 0
            const initialOrderAmounts = Object.keys(data).reduce((acc, ingredient) => {
                acc[ingredient] = 0;
                return acc;
            }, {});
            setOrderAmountMap(initialOrderAmounts);
        } catch (error) {
            console.error('Error fetching serving sizes:', error);
        }
    };


    useEffect(() => {
        fetchServingSizesAndInitializeOrders();
        console.log(servingSizeMap);

    }, []);



    /**
 * Navigates the user to the order page.
 */
    const navigateToOrderPage = () => {
        navigate('/customer');
    };


    return (
        <MainLayout>
          <div>
            <button className="Back" aria-label="Back Button: Click to navigate back to the drink menu" onClick={navigateToOrderPage}>
              Back
            </button>
          </div>
          <div className="btn-container">
            <button className='btn btn-outline-danger btn-sm' aria-label="Click to empty cart" onClick={() => clearCart()}> Clear Cart</button>
          </div>
          <div className='table-responsive bg-dark'>
            <table className='table table-responsive table-dark table-hover'>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Price</td>
                  <td>Toppings</td>
                  <td>Ice</td>
                  <td>Sugar</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {cart ? cart.map((cartProduct, key) => (
                  <tr key={key}>
                    <td tabIndex="0" aria-label={`${"Item in cart"} ${cartProduct.name} ${"costs $"} ${cartProduct.totalAmount} ${cartProduct.toppings.length > 0 ? "with" + cartProduct.toppings.length + "toppings." : "."} ${"Ice level"} ${cartProduct.icelevel} ${"and Sugar level"} ${cartProduct.sugarlevel}`}>
                      {cartProduct.name}
                    </td>
                    <td>{cartProduct.totalAmount}</td>
                    <td>{cartProduct.toppings?.length || 0}</td>
                    <td>{cartProduct.icelevel}</td>
                    <td>{cartProduct.sugarlevel}</td>
                    <td>
                      <div className="btn-container">
                        <button className='btn btn-danger btn-sm' tabIndex="0" aria-label={`${"Remove"} ${cartProduct.name} ${"from cart"}`} onClick={() => removeProduct(cartProduct)}>
                          <i className="bi bi-x-lg"></i>
                        </button>
                        <button className='btn btn-success btn-sm' tabIndex="0" aria-label={`${"Edit"} ${cartProduct.name}`} onClick={() => editProduct(cartProduct)}>
                          <i className="bi bi-pencil"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                )) : 'No Item in Cart'}
              </tbody>
            </table>
            <h2 className='px-2 text-white' tabIndex="0" aria-label={`${"Total Amount Owed is $"} ${totalAmount}`}>Total Amount: ${totalAmount}</h2>
      
            <div className='mt-3'>
              <input
                type="text"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                aria-label="Enter name here for checkout"
                placeholder='Enter Name'
                className='form-control mb-3'
              />
            </div>
      
            <div className="btn-container">
              <button className="btn btn-primary" aria-lable="Click to pay now" onClick={() => handlePayment('debit')} onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handlePayment('debit');
                }
                }} tabIndex="0">
                <span className="bi bi-currency-dollar"></span>Pay Now
              </button>
            </div>
          </div>
      
          {selectedProduct && (
            <ProductModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              onConfirm={onModalConfirm}
              product={selectedProduct}
            />
          )}
        </MainLayout>
      );
      }
      
      export default CustomerCheckoutPage;