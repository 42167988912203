// Import React and Axios
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Functional component
const MyComponent = () => {
  // State to store drink names and prices
  const [drinkNames, setDrinkNames] = useState([]);
  const [drinkPrices, setDrinkPrices] = useState([]);

  useEffect(() => {
    // Fetch drink names
    axios.get('https://yifang-server-2xpp.onrender.com/drinks')
      .then(response => {
        console.log('Response from drinks API:', response.data); // Log the response data
        setDrinkNames(response.data.drinkNames);
      })
      .catch(error => {
        console.error('Error fetching drink names:', error);
      });
  
    // Fetch drink prices
    axios.get('https://yifang-server-2xpp.onrender.com/prices')
      .then(response => {
        console.log('Response from prices API:', response.data); // Log the response data
        setDrinkPrices(response.data.drinkPrices);
      })
      .catch(error => {
        console.error('Error fetching drink prices:', error);
      });
  }, []);

  
  // Render component with fetched data
  return (
    <div>
      <h2>Drink Names</h2>
      {drinkNames.length === 0 ? (
        <p>No drink names available</p>
      ) : (
        <ul>
          {drinkNames.map((name, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      )}
  
      <h2>Drink Prices</h2>
      {drinkPrices.length === 0 ? (
        <p>No drink prices available</p>
      ) : (
        <ul>
          {drinkPrices.map((price, index) => (
            <li key={index}>{price}</li>
          ))}
        </ul>
      )}
    </div>
  );
  
};

export default MyComponent;
