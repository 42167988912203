import React from 'react';
import './MenuBoard.css';
import MainLayout from './cashierComponents/layouts/MainLayout'
import WeatherWidget from './weatherwidget';
import logo from './Assets/11rlogo.png'
import { useNavigate, Link } from 'react-router-dom';



/** 
* This is a functional component that represents an item on the Menu Board
* @component
* @example
*<MenuBoardItem key={index} itemName={item.itemName} description={item.description} price={item.price} image={item.image}/>
*/
const MenuBoardItem = ({ itemName, description, price, image }) => {

  const navigate = useNavigate();

      /**
   * Navigates to the front page.
   */
      const navigateToLoginPage = () => {
        navigate('/');
      };


  return (


    <div className="menu-item" tabIndex="0" aria-label={`${itemName} - ${description} - Price: $${price.toFixed(2)}`}>
      <div className="item-image">
        <img src={image} alt={itemName} />
      </div>

      <button className="login-Back-button" onClick={navigateToLoginPage} aria-label="Back to Front Page Button: Click to navigate back to the front page">
        Back to Front Page
      </button>

      <div className="item-details">
        <h3 className="item-name">{itemName}</h3>
        <p className="item-description">{description}</p>
        <p className="item-price">{`$${price.toFixed(2)}`}</p>
      </div>
    </div>
  );
};

export default MenuBoardItem;
