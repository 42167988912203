// Import useState if not already imported
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import Cashier from '../Cashier';
//import { isFormElement } from 'react-router-dom/dist/dom';

// Define an array of available toppings options
const toppingsOptions = [
  "Pearl",
  "Sago",
  "Aiyu Jelly",
  "Grass Jelly",
  "Almond Jelly",
  "Mochi Jelly Cake",
  "Aloe",
  "Agar Crystal Boba"
];

// ProductModal component definition
const ProductModal = ({ show, onHide, onConfirm, product, description }) => {
  const [localSelectedToppings, setLocalSelectedToppings] = useState([]);
  const [buttonText, setButtonText] = useState('Add to Cart');
  const [iceLevel, setIceLevel] = useState('100%');
  const [sugarLevel, setSugarLevel] = useState('100%');
  const [toppingStockStatus, setToppingStockStatus] = useState([]);
  const [servingSizeMap, setServingSizeMap] = useState({});
  const [toppingServingStatus, setToppingServingStatus] = useState([]);


  // Effect hook for initializing modal with product details or default
  useEffect(() => {
    console.log("Product in Modal:", product);
    console.log("Is Editing:", product.isEditing);

    if (product.isEditing) {
      setButtonText("Edit Drink");
      setLocalSelectedToppings(product.toppings);
      setIceLevel(product.icelevel);
      setSugarLevel(product.sugarlevel);
    } else {
      setButtonText("Add to Cart");
      setLocalSelectedToppings([]);
      setIceLevel('100%');
      setSugarLevel('100%');
    }
  }, [product, product.isEditing]);

  // Effect hook for fetching topping stock status from a server
  useEffect(() => {
    const fetchToppingStockStatus = async () => {
      const stockStatusPromises = toppingsOptions.map(async (topping) => {
        const quantityInStock = await getToppingQuantity(topping);
        return quantityInStock;
      });

      const resolvedStockStatus = await Promise.all(stockStatusPromises);
      setToppingStockStatus(resolvedStockStatus);
    };

    fetchToppingStockStatus();
  }, []);

  // Function to toggle a topping in the selected toppings list
  const toggleTopping = (topping) => {
    setLocalSelectedToppings(prev => {
      const newToppings = prev.includes(topping)
        ? prev.filter(t => t !== topping)
        : [...prev, topping];

      console.log("Updated Toppings:", newToppings);
      return newToppings;
    });
  };

   // Handler for confirming the product selection or edit
  const handleConfirm = () => {
    const toppingsCost = localSelectedToppings.length * 0.75;
    const newTotalAmount = (product.price + toppingsCost).toFixed(2);

    console.log("Confirming product with details:", {
      ...product,
      toppings: localSelectedToppings,
      totalAmount: newTotalAmount,
      icelevel: iceLevel,
      sugarlevel: sugarLevel
    });

    onConfirm({
      ...product,
      toppings: localSelectedToppings,
      totalAmount: newTotalAmount,
      icelevel: iceLevel,
      sugarlevel: sugarLevel
    });

    onHide(); // Hide the modal
  };

  // Async function to fetch the quantity of a specific topping from the server
  const getToppingQuantity = async (toppingName) => {
    try {
      const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/inventory/quantity/${encodeURIComponent(toppingName)}`);
      if (response.status === 404) {
        console.log("Nothing found in database for: ", toppingName);
        return -1;
      }
      else if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data.quantity;
    } catch (error) {
      console.error("Error fetching topping quantity: ", error);
      return 0;
    }
  };

  // Async function to fetch the serving size of toppings
  const getToppingServingSize = async (toppingName) => {
    try {
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/inventory/serving-sizes');
      if (response.status === 404) {
        console.log("Nothing found in database for: ", toppingName);
        return -1;
      }
      else if (!response.ok) {
        throw new Error(`HTTP Error! Stats: ${response.status}`);
      }
      const data = await response.json();
      setServingSizeMap(data);

    }
    catch {

    }
  };

  // Effect hook for fetching topping serving sizes
  useEffect(() => {
    const fetchToppingServingSize = async () => {
      const stockServingPromises = toppingsOptions.map(async (topping) => {
        const toppingServings = await getToppingServingSize(topping);
        return toppingServings;
      });

      const resolvedToppingServings = await Promise.all(stockServingPromises);
      setToppingServingStatus(resolvedToppingServings);
    };

    fetchToppingServingSize();
  }, []);

  const servingSizes = Object.values(servingSizeMap);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <Modal.Title tabIndex="0" aria-lable="Customize your drink in this Window">Customize your drink</Modal.Title>
          <p tabIndex="0" aria-label={`Drink description: ${description}`}>{description}</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Checkboxes for toppings */}
          {toppingsOptions.map((topping, index) => (
            <Form.Check
              key={index}
              type="checkbox"
              aria-label={`${localSelectedToppings.includes(topping) ? "Remove" : "Add"} ${topping} ${localSelectedToppings.includes(topping) ? "from" : "as"} topping${localSelectedToppings.includes(topping) ? " list, topping cost $0.75" : " for an additional $0.75"}`}
              label={`${topping} (${toppingStockStatus[index] > servingSizes[index] ? "+$0.75)" : "Out of Stock)"}`}
              checked={localSelectedToppings.includes(topping)}
              disabled={toppingStockStatus[index] < servingSizes[index]}
              onChange={() => {
                console.log(`Topping: ${topping}, Quantity in stock: ${toppingStockStatus[index]}`);
                toggleTopping(topping);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  toggleTopping(topping);
                }
              }}
            />
          ))}
          {/* Ice Level Selection */}
          <Form.Group>
            <Form.Label tabIndex="0" aria-label="Select your ice level, currently 100 percent">Ice Level</Form.Label>
            <div className="d-flex">
              {["0%", "25%", "50%", "75%", "100%"].map((level, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  label={level}
                  name="iceLevel"
                  aria-label={level}
                  value={level}
                  checked={iceLevel === level}
                  onChange={(e) => setIceLevel(e.target.value)}
                  className="mx-2"
                />
              ))}
            </div>
          </Form.Group>
  
          {/* Sugar Level Selection */}
          <Form.Group>
            <Form.Label tabIndex="0" aria-label="Select your sugar level, currently 100%">Sugar Level</Form.Label>
            <div className="d-flex">
              {["0%", "25%", "50%", "75%", "100%"].map((level, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  label={level}
                  name="sugarLevel"
                  aria-label={level}
                  value={level}
                  checked={sugarLevel === level}
                  onChange={(e) => setSugarLevel(e.target.value)}
                  className="mx-2"
                />
              ))}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" aria-label="Button: Remove drink from order and return to the drink menu" onClick={onHide}>Close</Button>
        <Button variant="primary" aria-label="Add drink to cart and proceed" onClick={handleConfirm}>{buttonText}</Button>
      </Modal.Footer>
    </Modal>
  );
  };
  
  export default ProductModal;
