// SalesReport.js
import React, { useState } from 'react';
import axios from 'axios';
import './SalesReport.css';


/**
 * Functional component representing the Sales Report page.
 * @returns {JSX.Element} JSX element representing the SalesReport component.
 */
const SalesReport = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [drinkName, setDrinkName] = useState('');
  const [salesData, setSalesData] = useState({ allSales: [], salesWithCondition: [] });
  const [loading, setLoading] = useState(false); 
  const [reportGenerated, setReportGenerated] = useState(false);
  const [validDrinkSelected, setValidDrinkSelected] = useState(false);

  /**
   * Handles the change in date, resetting selected drink and report generation status.
   */
  const handleDateChange = () => {
    setDrinkName('');
    setReportGenerated(false);
    setValidDrinkSelected(false);
  };

    /**
   * Handles the form submission to generate the sales report.
   * @param {Event} e - The form submission event.
   */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Basic input validation
    if (!startDate || !endDate || !startTime || !endTime) {
      window.alert('Please fill in all date and time.');
      return;
    }

    try {
      setLoading(true);
      setSalesData(null);

      // Format date and time values
      const formattedStartDate = startDate;
      const formattedEndDate = endDate;
      const formattedStartTime = startTime;
      const formattedEndTime = endTime;

      // Make a request to the first backend endpoint
      const response1 = await axios.get('https://yifang-server-2xpp.onrender.com/api/sold-drinks', {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
        },
      });

      // Make a request to the second backend endpoint
      const response2 = await axios.get('https://yifang-server-2xpp.onrender.com/api/sold-drinks-with-condition', {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          drinkName: drinkName,
        },
      });

      // Set the results from both endpoints in state
      setSalesData({
        allSales: response1.data.drinkSales,
        salesWithCondition: response2.data.drinkSales,
      });

      setReportGenerated(true);

      if (drinkName !== ""){
        setValidDrinkSelected(true);
      }
      

      console.log('Response from backend 1:', response1.data.drinkSales);
      console.log('Response from backend 2:', response2.data.drinkSales);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <h2 className='prevent'>Sales Report</h2>

      <form onSubmit={handleFormSubmit}>
        <div className="form-row">
          <label htmlFor="startDateTime" className="form-label">
            Start Date and Time:
          </label>
          <input
            id="startDateTime"
            className="form-input"
            type="datetime-local"
            value={startDate + 'T' + startTime}
            onChange={(e) => {
              const dateTime = e.target.value.split('T');
              setStartDate(dateTime[0]);
              setStartTime(dateTime[1]);
              handleDateChange();
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="endDateTime" className="form-label">
            End Date and Time:
          </label>
          <input
            id="endDateTime"
            className="form-input"
            type="datetime-local"
            value={endDate + 'T' + endTime}
            onChange={(e) => {
              const dateTime = e.target.value.split('T');
              setEndDate(dateTime[0]);
              setEndTime(dateTime[1]);
              handleDateChange();
            }}
          />
        </div>

        <br />
        {!reportGenerated && (
          <button className="Report-Button" type="submit">
            Generate Report
          </button>
        )}
      </form>

      {loading && <p>Loading...</p>}

      {reportGenerated && salesData && (
        <div>
          <h3>Item name and total amount sold from all sales</h3>
          <ul className="sales-data-list">
            {salesData.allSales.map((item) => (
              <li className="sales-data-list-item" key={item.menu_item}>
                {item.menu_item}: {item.total_drinks_sold}
              </li>
            ))}
          </ul>

          <div className="form-row">
          <label htmlFor="drinkName" className="form-label">
            Drink Name:
          </label>
          <select
            id="drinkName"
            className="form-input"
            value={drinkName}
            onChange={(e) => {
              setDrinkName(e.target.value);
            }}
          >
            <option value="" disabled>Select a drink</option>
            {salesData && salesData.allSales && salesData.allSales.length > 0 &&
              salesData.allSales
                .filter((item) => item.menu_item !== "") // Exclude items with empty menu_item
                .map((item) => (
                  <option key={item.menu_item} value={item.menu_item}>
                    {item.menu_item}
                  </option>
                ))}
          </select>
        </div>

        {reportGenerated &&(
          <button className="Reported-Button" onClick={handleFormSubmit}>
            Generate Report
          </button>
        )}


          {validDrinkSelected && (
            <div> 
              <h3>Orders including selected drink</h3>
              <ul className="sales-data-list">
                {salesData.salesWithCondition.map((item) => (
                  <li className="sales-data-list-item" key={item.orderid}>
                    <div>
                      <strong>Customer Name:</strong> {item.customername}
                    </div>
                    <div>
                      <strong>Order ID:</strong> {item.orderid}
                    </div>
                    <div>
                      <strong>Payment Method:</strong> {item.paymentmethod}
                    </div>
                    <div>
                      <strong>Date:</strong> {new Date(item.teadate).toLocaleDateString()}
                    </div>
                    <div>
                      <strong>Time:</strong> {item.teatime}
                    </div>
                    <div>
                      <strong>Total Price:</strong> {item.totalprice}
                    </div>
                    <div className="centered-sales-list">
                      <strong>Drinks:</strong>
                      <ul>
                        {item.drinks.map((drink, index) => (
                          <ul key={index}>{drink}</ul>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>

            </div>
          )}

        </div>
      )}



    </div>
  );
};

export default SalesReport;
