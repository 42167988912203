import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SalesReport from './SalesReport';
import RestockReport from './RestockReport';
import ExcessReport from './ExcessReport';
import PairedItems from './PairedItems';
import './TrendsPage.css';
import MainLayout from './cashierComponents/layouts/MainLayout'


/**
 * Functional component representing the Trends Page of the application.
 * @returns {JSX.Element} JSX element representing the TrendsPage component.
 */
const TrendsPage = () => {
  const [selectedReport, setSelectedReport] = useState(null);


    /**
   * Handles button click to set the selected report type.
   * @param {string} reportType - The type of report ('Sales', 'Restock', 'Excess', 'Paired').
   */
  const handleButtonClick = (reportType) => {
    setSelectedReport(reportType);
  };


  /**
   * Renders the selected report based on the current state.
   * @returns {JSX.Element} JSX element representing the selected report component.
   */
  const renderReport = () => {
    switch (selectedReport) {
      case 'Sales':
        return <SalesReport />;
      case 'Restock':
        return <RestockReport />;
      case 'Excess':
        return <ExcessReport />;
      case 'Paired':
        return <PairedItems />;
      default:
        return null;
    }
  };

  return (
    <MainLayout>
    <div className="trends-page">

      <div className="trends_navi_button">
        <Link to="/ManagerFrontPage">Manager Navigation</Link>
      </div>

      <div className="top-section">
        <button onClick={() => handleButtonClick('Sales')}>Sales Report</button>
        <button onClick={() => handleButtonClick('Restock')}>Restock Report</button>
        <button onClick={() => handleButtonClick('Excess')}>Excess Report</button>
        <button onClick={() => handleButtonClick('Paired')}>Commonly Paired Items</button>
      </div>

      <div className="bottom-section">
        {renderReport()}
      </div>
    </div>
    </MainLayout>
  );
};

export default TrendsPage;
