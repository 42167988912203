import React from 'react';
import { Link } from 'react-router-dom';
import './ManagerFrontPage.css';
import WeatherWidget from './weatherwidget';
import logo from './Assets/11rlogo.png'


/**
 * Functional component representing the Manager Front Page.
 * @returns {JSX.Element} The rendered Manager Front Page component.
 */
function ManagerFrontPage() {
  const apiKey = '1fa723272ecc0ef8b81e9d836e15b7ae';
  return (
    <div>
      <header>
        <nav className="navbar navbar-light manager-header-color">
          <div className="container d-flex justify-content-between">

            {/* Empty Div for spacing */}
            <div className="d-flex align-items-center ms-auto" style= {{ opacity: 0, pointerEvents : 'none'}}>
              <WeatherWidget apiKey={apiKey} />
            </div>

            {/* YiFang Fruit Tea Image */}
            <Link to="/" className="navbar-brand d-flex justify-content-center ">
              <img
                src={logo}
                alt="YiFang Fruit Tea Logo"
                className="header-logo"
              />
            </Link>

            {/* Container for Translate Button and Weather Widget */}
            <div className="d-flex align-items-center ms-auto">
              <WeatherWidget apiKey={apiKey} />
            </div>

          </div>
        </nav>
      </header>
    <div className='manager_front_overlay'>
      <Link to="">
        <img src="https://images.squarespace-cdn.com/content/v1/63082a5d7ba9d655b1718056/c456ce98-67a2-49e8-9ac8-315548c0cf7c/black-logo.png" 
        alt="YiFang Logo"
        className="manager_front_link"
        />
      </Link>
      <h1 className='manager_front_title'>Manager Page</h1>
      <div className="manager_front_buttons">
        <Link to="/TrendsPage">
          Trends Report
        </Link>

        <Link to="/ManagerPageTest">
          Manage Inventory
        </Link>

        <Link to="/Edit">
          Edit drink
        </Link>

        <Link to="/Login">
          Back to login
        </Link>
      </div>
    </div>
    </div>
  );
}

export default ManagerFrontPage;
