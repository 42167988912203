import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Manager.css';
import { useNavigate, Link } from 'react-router-dom';
import CustomAlert from './ManagerComponents/CustomAlert';
import MainLayout from './cashierComponents/layouts/MainLayout';

/**
 * Functional component representing the manager page for testing.
 * @returns {JSX.Element} JSX element representing the ManagerPageTest component.
 */
function ManagerPageTest() {
  // Navigation
  const navigate = useNavigate();

  // State variables
  const [items, setItems] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [updateQuantity, setUpdateQuantity] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newItemData, setNewItemData] = useState({
    itemName: '',
    newQuantity: '',
    newServingSize: '',
    newUnit: '',
  });

  // Alert variables
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState('');

  // Search variables
  const [searchTerm, setSearchTerm] = useState('');

  /**
   * Filters items based on the search term.
   * @type {Array} filteredItems - Array of items matching the search term.
   */
  const filteredItems = items.filter((item) =>
    item.inventory_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  /**
   * Handles closing custom alerts.
   */
  const handleCustomAlertClose = () => {
    setShowCustomAlert(false);
    setCustomAlertMessage('');
  };

  /**
   * Displays a custom alert with the specified message.
   * @param {string} message - The message to be displayed in the custom alert.
   */
  const showAlert = (message) => {
    setCustomAlertMessage(message);
    setShowCustomAlert(true);
  };

  /**
   * Handles the click event when the "Add Item" button is clicked.
   * @param {Event} e - The click event.
   */
  const handleAddItemClick = (e) => {
    e.preventDefault();
    addNewItem();
  };

  /**
   * Clears the form for adding a new item.
   */
  const clearForm = () => {
    setNewItemData({
      itemName: '',
      newQuantity: '',
      newServingSize: '',
      newUnit: '',
    });
  };

  /**
   * Adds a new item to the inventory.
   */
  const addNewItem = async () => {
    try {
      const existingItem = items.find(item => item.inventory_name === newItemData.itemName);
      if (existingItem) {
        showAlert(`Item '${newItemData.itemName}' already exists.`);
        return;
      }

      const response = await axios.post(
        'https://yifang-server-2xpp.onrender.com/ManagerPageTest_Add',
        {
          itemName: newItemData.itemName,
          newQuantity: newItemData.newQuantity,
          newUnit: newItemData.newUnit,
          newServingSize: newItemData.newServingSize,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        fetchData();
        setShowAddModal(false);
        showAlert(`'${newItemData.itemName}' added!`);
        clearForm();
      } else {
        throw new Error(`Error adding item: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error adding item:', error.message);
    }
  };

  /**
   * Fetches data for the table.
   */
  const fetchData = () => {
    axios.get('https://yifang-server-2xpp.onrender.com/ManagerPageTest_Table')
      .then(response => {
        console.log('Response from ManagerPageTest_Table API:', response.data);
        setItems(response.data.items);
      })
      .catch(error => {
        console.error('Error fetching inventory items:', error);
      });
  };

  /**
   * Navigates back to the login page.
   */
  const navigateToLogin = () => {
    navigate('/ManagerFrontPage');
  };

  /**
   * Initiates the removal process for an item.
   * @param {string} itemId - The ID of the item to be removed.
   */
  const removeItem = (itemId) => {
    setSelectedItemId(itemId);
    setShowRemoveModal(true);
  };

  /**
   * Initiates the update process for an item.
   * @param {string} itemId - The ID of the item to be updated.
   */
  const updateItem = (itemId) => {
    setSelectedItemId(itemId);
    setShowUpdateModal(true);
  };

  /**
   * Confirms the removal of an item.
   */
  const confirmRemoveItem = () => {
    if (selectedItemId) {
      const selectedItem = items.find(item => item.inventory_name === selectedItemId);
      axios.delete(`https://yifang-server-2xpp.onrender.com/ManagerPageTest_Remove/${selectedItemId}`)
        .then(response => {
          console.log('Item removed successfully:', response.data);
          fetchData();
          showAlert(`'${selectedItem ? selectedItem.inventory_name : selectedItemId}' removed!`);
        })
        .catch(error => {
          console.error('Error removing item:', error);
        })
        .finally(() => {
          setSelectedItemId(null);
          setShowRemoveModal(false);
        });
    }
  };

  /**
   * Confirms the update of an item.
   */
  const confirmUpdateItem = () => {
    if (selectedItemId) {
      const QuantityValue = parseFloat(updateQuantity.trim());
      const selectedItem = items.find(item => item.inventory_name === selectedItemId);

      if (!isNaN(QuantityValue) && QuantityValue >= 0) {
        axios.put(`https://yifang-server-2xpp.onrender.com/ManagerPageTest_Update/${selectedItemId}/${updateQuantity}`)
          .then(response => {
            console.log('Item updated successfully:', response.data);
            fetchData();
            showAlert(`'${selectedItem ? selectedItem.inventory_name : selectedItemId}' amount updated!`);
          })
          .catch(error => {
            console.error('Error updating item:', error);
          })
          .finally(() => {
            setSelectedItemId(null);
            setUpdateQuantity('');
            setShowUpdateModal(false);
          });
      } else {
        showAlert('Please enter a valid quantity equal to or greater than 0.');
      }
    } else {
      showAlert('Please enter a valid quantity.');
    }
  };

  /**
   * Fetches data on the component mount.
   */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Renders the ManagerPageTest component.
   * @returns {JSX.Element} JSX element.
   */
  

  return (
    <MainLayout>
    <div className="table-container">
      {showCustomAlert && (
        <CustomAlert message={customAlertMessage} onClose={handleCustomAlertClose} />
      )}
      <div className="manager-navigation-bottons">
        <button className="inventory-navi-button" onClick={(navigateToLogin)}>Manager Navigation</button>
      </div>

      <Link to="/">
        <img src="https://images.squarespace-cdn.com/content/v1/63082a5d7ba9d655b1718056/c456ce98-67a2-49e8-9ac8-315548c0cf7c/black-logo.png" 
        alt="YiFang Logo"
        className="logo-img-manager"
        />
      </Link>
      <h1>Inventory</h1>
      <div>
        <br></br>
        <input
          className = "prevent"
          type="text"
          placeholder="Search by Item Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <br></br>
      <div>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>On Hand</th>
              <th>Product ID</th>
              <th>Serving Size</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Map through the items and render rows dynamically */}
            {filteredItems.map((item) => (
              <tr key={item.id}>
                <td>{item.inventory_name}</td>
                <td>{item.quantity}</td>
                <td>{item.id}</td>
                <td>{item.serving_size}</td>
                <td>
                <button
                  className='remove_button'
                  onClick={() => removeItem(item.inventory_name)}>Remove</button>
                <button 
                  className='button_class' 
                  onClick={() => updateItem(item.inventory_name)}>Update</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showRemoveModal && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal">
            <p>Are you sure you want to remove {selectedItemId}?</p>
            <div className="confirmation-modal-buttons">
            <button className="remove_button" onClick={confirmRemoveItem}>Yes</button>
            <button className="button_class" onClick={() => {setSelectedItemId(null); setShowRemoveModal(false)}}>No</button>
            </div>
          </div>
        </div>
      )}
      {showUpdateModal && (
        <div className="update-modal-overlay">
          <div className="update-modal">
            <p>Enter new quantity for {selectedItemId}:</p>
            <input
              className = "prevent"
              type="text"
              value={updateQuantity}
              onChange={(e) => setUpdateQuantity(e.target.value)}
            />
            <div className="update-modal-buttons">
              <button className="button_class" onClick={confirmUpdateItem}>Update</button>
              <button className="button_class" onClick={() => {setShowUpdateModal(false); setUpdateQuantity('')}}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      
      <div >
        <button className="add-button" onClick={() => setShowAddModal(true)}>Add Item</button>
      </div>

      {showAddModal && (
        <div className="add-modal-overlay">
        <div className="add-modal">
          <h2 className='prevent'>Add New Item</h2>
          <form>
            <label htmlFor="itemName" className='prevent'>Item Name:</label>
            <input
              className='prevent'
              type="text"
              id="itemName"
              value={newItemData.itemName}
              onChange={(e) => setNewItemData({ ...newItemData, itemName: e.target.value })}
            />

            <label htmlFor="newQuantity">Quantity:</label>
            <input
              className='prevent'
              type="text"
              id="newQuantity"
              value={newItemData.newQuantity}
              onChange={(e) => setNewItemData({ ...newItemData, newQuantity: e.target.value })}
            />

            <label htmlFor="newServingSize">Serving Size:</label>
            <input
              className='prevent'
              type="text"
              id="newServingSize"
              value={newItemData.newServingSize}
              onChange={(e) => setNewItemData({ ...newItemData, newServingSize: e.target.value })}
            />

            <label htmlFor="newUnit">Unit:</label>
            <input
              className='prevent'
              type="text"
              id="newUnit"
              value={newItemData.newUnit}
              onChange={(e) => setNewItemData({ ...newItemData, newUnit: e.target.value })}
            />

            <div className="add-modal-buttons">
              <button className="button_class" onClick={(e) => handleAddItemClick(e)}>
                Add Item
              </button>
              <button className="button_class" onClick={() => { setShowAddModal(false); clearForm(); }}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      )}

      </div>
      </MainLayout>
    
  );
}

export default ManagerPageTest;
