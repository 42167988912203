import React, { useEffect, useRef, useState } from 'react'
import MainLayout from './cashierComponents/layouts/MainLayout'
import { toast } from 'react-toastify';
import { ComponentToPrint } from './cashierComponents/ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import localData from './data/db.json';
import './cashierComponents/cashier.css';
import ProductModal from './cashierComponents/ProductModal';
import { v4 as uuidv4 } from 'uuid';
import defaultImg from './cashierComponents/default_img.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function Cashier() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedToppings, setSelectedToppings] = useState([]);

  const [servingSizeMap, setServingSizeMap] = useState({});
  const [orderAmountMap, setOrderAmountMap] = useState({});
  const [originalProduct, setOriginalProduct] = useState(null);

  const [customerName, setCustomerName] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [productsInStock, setProductsInStock] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = ["Tea Lattes", "Flavored Teas", "Milk Teas", "Other"];

  /**
 * Configuration options for toast notifications.
 * @type {{autoClose: number, pauseOnHover: boolean}}
 */
  const toastOptions = {
    autoClose: 400, // Time in ms before the toast auto-closes
    pauseOnHover: true, // Whether the toast pauses closing when hovered over
  };

  /**
   * Updates the cart with a new set of items and saves to local storage.
   * @param {Array} newCart - The new cart array to be set.
   */
  const updateCart = (newCart) => {
    setCart(newCart); // Updates the state with the new cart
    console.log("Cart: ", newCart); // Logging for debugging
    localStorage.setItem('cart', JSON.stringify(newCart)); // Persists the cart to local storage
  };

  /**
   * Clears all items from the cart and resets the order amount map.
   */
  const clearCart = () => {
    updateCart([]); // Clears the cart by setting it to an empty array
    // Reset the orderAmountMap to zero for all items
    const resetOrderAmounts = Object.keys(orderAmountMap).reduce((acc, key) => {
      acc[key] = 0;
      return acc;
    }, {});
    setOrderAmountMap(resetOrderAmounts); // Update the state with the reset amounts
  };

  // Log updates to orderAmountMap and servingSizeMap for debugging
  useEffect(() => {
    console.log("Amount Order Map updated:", orderAmountMap);
  }, [orderAmountMap]);
  useEffect(() => {
    console.log("Serving Size Map updated:", servingSizeMap);
  }, [servingSizeMap]);

  // Function to edit a selected product
  /**
 * Prepares and opens the modal for editing a selected product.
 * @param {Object} product - The product to be edited.
 */
  const editProduct = (product) => {
    console.log('Editing product', product); // Log the product being edited
    setOriginalProduct(product); // Sets the product as the original for reference
    setSelectedProduct({ ...product, isEditing: true }); // Marks the product as being edited
    setSelectedToppings(product.toppings); // Sets the currently selected toppings
    setModalShow(true); // Opens a modal for editing
    toast(`Editing ${product.name}`, toastOptions); // Shows a toast notification for editing
  };

  // Function to add a product to the cart
  /**
 * Adds a product to the cart if enough inventory is available.
 * @param {Object} product - The product to be added to the cart.
 */
  const addProductToCart = async (product) => {
    const enoughInventory = await isEnoughInventoryForProduct(product); // Checks inventory for product availability
    if (enoughInventory) {
      openModal(product); // Opens a modal if enough inventory is available
    } else {
      // Shows an error toast if not enough inventory
      toast.error("Not enough inventory for this product", {
        // Toast configuration
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Function to remove a product from the cart
  /**
 * Removes a product from the cart.
 * @param {Object} product - The product to be removed from the cart.
 */
  const removeProduct = async (product) => {
    // Filters out the product to be removed from the cart
    const newCart = cart.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    removeToppingAndIngredient(product); // Additional function to handle toppings and ingredients
    updateCart(newCart); // Updates the cart with the new list
  };

  const componentRef = useRef(); // React ref, possibly for DOM access or storing a mutable value

  // Function to check if enough inventory is available for a product
  /**
 * Checks if there is enough inventory for a product.
 * @param {Object} product - The product to check inventory for.
 * @returns {Promise<boolean>} - True if enough inventory, false otherwise.
 */
  const isEnoughInventoryForProduct = async (product) => {
    // Loop through each ingredient of the product
    for (const ingredient of product.ingredients) {
      // Calculate the required quantity for this ingredient
      const requiredQuantity = (orderAmountMap[ingredient] || 0) + (servingSizeMap[ingredient] || 0);
      const availableQuantity = await getIngredientQuantity(ingredient); // Fetch the available quantity
      if (requiredQuantity > availableQuantity) {
        console.log("Drink DOES NOT have enough ingredients in inventory");
        return false; // Return false if not enough inventory
      }
    }
    console.log("Drink DOES have enough ingredients in inventory");
    return true; // Return true if enough inventory is available
  };

  // Similar function for checking inventory, but specifically for product toppings
  /**
 * Checks if there is enough inventory for the toppings of a product.
 * @param {Object} productWithOptions - Product with options such as toppings.
 * @returns {Promise<boolean>} - True if enough inventory, false otherwise.
 */
  const isEnoughInventoryForProductTopping = async (productWithOptions) => {
    // Loop through each topping of the product
    for (const topping of productWithOptions.toppings) {
      let requiredQuantity = (orderAmountMap[topping] || 0) + (servingSizeMap[topping] || 0);
      if (productWithOptions.isEditing) {
        const originalToppings = originalProduct.toppings; // Get original toppings if editing
        if (originalToppings.includes(topping)) {
          requiredQuantity -= servingSizeMap[topping]; // Adjust quantity if topping was already in the original product
        }
      }
      const availableQuantity = await getIngredientQuantity(topping); // Fetch the available quantity
      if (requiredQuantity > availableQuantity) {
        console.log("Drink DOES NOT have enough toppings in inventory");
        return false; // Return false if not enough inventory for toppings
      }
    }
    console.log("Drink DOES have enough toppings in inventory");
    return true; // Return true if enough inventory for toppings
  };

  /**
 * Fetches the quantity of a specific ingredient.
 * @param {string} ingredientName - Name of the ingredient.
 * @returns {Promise<number>} - The quantity of the ingredient.
 */
  const getIngredientQuantity = async (ingredientName) => {
    try {
      console.log("ingredient name:", ingredientName);
      const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/inventory/quantity/${encodeURIComponent(ingredientName)}`);
      if (response.status === 404) {
        // Ingredient not found in the database, return 0
        console.log("Nothing found in database for: ", ingredientName);
        return -1;
      } else if (!response.ok) {
        // Some other error occurred
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      console.log(`Quantity of ${ingredientName}: ${data.quantity}`);

      return data.quantity; // Assuming the server responds with an object containing the quantity
    } catch (error) {
      console.error('Error fetching ingredient quantity:', error);
      return 0; // Return a default value or handle the error as appropriate
    }
  };

  // Function to fetch serving sizes and initialize orders
  /**
 * Fetches serving sizes and initializes orders.
 */
  const fetchServingSizesAndInitializeOrders = async () => {
    try {
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/inventory/serving-sizes');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setServingSizeMap(data);

      // Initialize orderAmountMap with the same keys, values set to 0
      const initialOrderAmounts = Object.keys(data).reduce((acc, ingredient) => {
        acc[ingredient] = 0;
        return acc;
      }, {});

      setOrderAmountMap(initialOrderAmounts);
    } catch (error) {
      console.error('Error fetching serving sizes:', error);
    }
  };
  /**
 * Fetches products from a remote server.
 */
  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/products');
      const data = await response.json();
      if (data && data.products) {
        mergeProductData(data.products);
      } else {
        console.error('API response is missing the products field:', data);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProducts();
    fetchServingSizesAndInitializeOrders();
    console.log(servingSizeMap);

  }, []);

  useEffect(() => {
    const checkAllProductsStock = async () => {
      let stockStatus = {};
      for (const product of products) {
        stockStatus[product.name] = await isEnoughInventoryForProduct(product);
      }
      console.log("Drinks in stock: ", stockStatus);
      setProductsInStock(stockStatus);
    };

    checkAllProductsStock();
  }, [products, orderAmountMap, servingSizeMap]); // Add dependencies as needed

  /**
 * Merges product data from API with local data.
 * @param {Array} apiProducts - Array of products fetched from an API.
 */
  const mergeProductData = (apiProducts) => {
    const mergedProducts = apiProducts.map(apiProduct => {
      const localProduct = localData.products.find(p => p.name === apiProduct.name);
      if (localProduct) {
        return { ...apiProduct, ...localProduct };
      } else {
        return {
          ...apiProduct,
          description: "No description available", // Default description
          image: defaultImg, // Default image
          category: "Other"
        };
      }
    });
    console.log("Merged from DB: ", mergedProducts)
    setProducts(mergedProducts);
  };

  /**
 * Updates the total amount whenever the cart changes.
 */
  useEffect(() => {
    let newTotalAmount = 0;
    cart.forEach(cartItem => {
      newTotalAmount += parseFloat(cartItem.totalAmount)
    });
    setTotalAmount(newTotalAmount.toFixed(2));
  }, [cart]);

  /**
 * Loads the cart from localStorage on component mount.
 */
  useEffect(() => {
    // Load cart from localStorage
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  /**
 * Opens a modal for product selection or editing.
 * @param {Object} product - The product to be added or edited.
 */
  const openModal = (product) => {
    setSelectedProduct({ ...product, toppings: [], isEditing: false });
    setModalShow(true);
  };

  /**
 * Handles the confirmation action in the modal.
 * @param {Object} productWithOptions - The product with selected options like toppings.
 */
  const onModalConfirm = async (productWithOptions) => {

    let allToppingsAvailable = await isEnoughInventoryForProductTopping(productWithOptions);

    if (allToppingsAvailable) {
      addIngredientAndToppingAmounts(productWithOptions);

      if (productWithOptions.isEditing) {
        editProductInCart(productWithOptions);
      } else {
        addProductWithOptionsToCart(productWithOptions);
      }
      console.log("Order Amount Map: ", orderAmountMap);
    } else {
      // Handle the case where there isn't enough inventory for the toppings
      toast.error("Not enough inventory for topping chosen", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }

    setModalShow(false);
  };

  /**
 * Removes the quantities of toppings and ingredients from the order amount map.
 * @param {Object} productWithOptions - The product from which toppings and ingredients are to be removed.
 */
  const removeToppingAndIngredient = (productWithOptions) => {
    const updatedOrderAmounts = { ...orderAmountMap };
    const toppings = productWithOptions.toppings;
    const ingredients = productWithOptions.ingredients;

    console.log("Removing toppings");
    toppings.forEach(topping => {
      const servingSize = servingSizeMap[topping] || 0;
      updatedOrderAmounts[topping] = Math.max((updatedOrderAmounts[topping] || 0) - servingSize, 0);
    });
    console.log("Removing ingredients");
    ingredients.forEach(ingredient => {
      const servingSize = servingSizeMap[ingredient] || 0;
      updatedOrderAmounts[ingredient] = Math.max((updatedOrderAmounts[ingredient] || 0) - servingSize, 0);
    });
    setOrderAmountMap(updatedOrderAmounts);
  };

  /**
 * Adds the quantities of toppings and ingredients to the order amount map.
 * @param {Object} productWithOptions - The product to which toppings and ingredients are to be added.
 */
  const addIngredientAndToppingAmounts = (productWithOptions) => {
    const updatedOrderAmounts = { ...orderAmountMap };
    if (!productWithOptions.isEditing) {
      const ingredients = productWithOptions.ingredients;
      const toppings = productWithOptions.toppings;
      console.log("Ingredients being added: ", ingredients);

      ingredients.forEach(ingredient => {
        const servingSize = servingSizeMap[ingredient] || 0;
        updatedOrderAmounts[ingredient] = (updatedOrderAmounts[ingredient] || 0) + servingSize;
      });

      console.log("Toppings being added: ", toppings);
      toppings.forEach(topping => {
        const servingSize = servingSizeMap[topping] || 0;
        updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) + servingSize;
      });
    } else {
      const originalToppings = originalProduct.toppings;
      const newToppings = productWithOptions.toppings;

      // Decrement order amount map for toppings removed from the product
      originalToppings.forEach(topping => {
        if (!newToppings.includes(topping)) {
          const servingSize = servingSizeMap[topping] || 0;
          updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) - servingSize;
        }
      });

      // Increment order amount map for toppings added to the product
      newToppings.forEach(topping => {
        if (!originalToppings.includes(topping)) {
          const servingSize = servingSizeMap[topping] || 0;
          updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) + servingSize;
        }
      });
      setOriginalProduct(null);
    }

    setOrderAmountMap(updatedOrderAmounts);
  };

  /**
 * Edits a product in the cart, updating its details.
 * @param {Object} product - The product to be updated in the cart.
 */
  const editProductInCart = (product) => {
    // Find the item we're updating
    const existingProductIndex = cart.findIndex((cartItem) => cartItem.cartItemId === product.cartItemId);

    if (existingProductIndex > -1) {
      // Update the existing product
      const newCart = [...cart];
      const existingProduct = newCart[existingProductIndex];

      // Calculate the new total amount
      const newTotalAmount = (parseFloat(product.price) + (product.toppings.length * 0.75)).toFixed(2);

      newCart[existingProductIndex] = {
        ...existingProduct,
        totalAmount: newTotalAmount,
        toppings: product.toppings,
        icelevel: product.icelevel,
        sugarlevel: product.sugarlevel
      };

      // Update the cart with the new list
      updateCart(newCart);
      toast(`Updated ${product.name} with new toppings in cart`, toastOptions);
    } else {
      // Product to edit not found in cart, handle error
      console.error('Product to edit not found in cart');
    }
  };

  /**
 * Adds a product with options (like toppings) to the cart.
 * @param {Object} product - The product to be added to the cart.
 */
  const addProductWithOptionsToCart = (product) => {


    const newProduct = {
      ...product,
      cartItemId: uuidv4(), // This assigns a unique UUID to each cart item
    };

    updateCart([...cart, newProduct]);

    toast(`Added ${product.name} with toppings to cart`, toastOptions);
  };

  /**
 * Determines the number of decimal places in a number.
 * @param {number} num - The number to check.
 * @returns {number} - Number of decimal places.
 */
  const getDecimalPlaces = (num) => {
    if (!isNaN(num) && Math.floor(num) !== num) {
      return num.toString().split(".")[1].length;
    }
    return 0;
  };

  /**
 * Rounds a number to the maximum decimal places of reference numbers.
 * @param {number} numToRound - The number to round.
 * @param {number} referenceNum1 - The first reference number.
 * @param {number} referenceNum2 - The second reference number.
 * @returns {number} - The rounded number.
 */
  const roundToMaxDecimalPlaces = (numToRound, referenceNum1, referenceNum2) => {
    const decimalPlaces1 = getDecimalPlaces(referenceNum1);
    const decimalPlaces2 = getDecimalPlaces(referenceNum2);
    const maxDecimalPlaces = Math.max(decimalPlaces1, decimalPlaces2);

    return parseFloat(numToRound.toFixed(maxDecimalPlaces));
  };

  /**
 * Rounds a quantity to the decimal places of the serving size.
 * @param {number} quantity - The quantity to round.
 * @param {number} servingSize - The serving size to use for rounding.
 * @returns {number} - The rounded quantity.
 */
  const roundQuantity = (quantity, servingSize) => {
    const decimalPlaces = getDecimalPlaces(servingSize);
    return parseFloat(quantity.toFixed(decimalPlaces));
  };

  /**
 * Updates the quantity of an inventory item in the database.
 * @param {string} itemName - The name of the item to update.
 * @param {number} newQuantity - The new quantity to set.
 * @returns {Promise<boolean>} - True if update is successful, false otherwise.
 */
  const updateInventoryItem = async (itemName, newQuantity) => {
    try {
      const url = `https://yifang-server-2xpp.onrender.com/ManagerPageTest_Update/${encodeURIComponent(itemName)}/${newQuantity}`;
      console.log(`Updating inventory with URL: ${url}`);

      const response = await axios.put(url);

      if (response.data.success) {
        return true;
      } else {
        throw new Error(`API responded with success: false for ${itemName}`);
      }
    } catch (error) {
      console.error(`Error updating inventory for ${itemName}:`, error);
      return false;
    }
  };

  /**
 * Calculates the new inventory quantity for an item after usage.
 * @param {string} itemName - The name of the inventory item.
 * @param {number} usedQuantity - The quantity of the item used.
 * @returns {Promise<number>} - The new inventory quantity after deduction.
 */
  const calculateNewInventoryQuantity = async (itemName, usedQuantity) => {
    // Fetch current quantity from inventory
    // Implement fetching current inventory quantity logic here
    const currentQuantity = await getIngredientQuantity(itemName);
    return Math.max(currentQuantity - usedQuantity, 0); // Ensure the quantity doesn't go below 0
  };

  /**
 * Handles the payment process based on the selected payment method.
 * @param {string} paymentMethod - The chosen method of payment (e.g., 'cash').
 */
  const handlePayment = async (paymentMethod) => {
    if (customerName === '') {
      toast.error("No customer name given", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!(cart.length > 0)) {
      toast.error("No items in cart", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (paymentMethod === 'cash') {
      // if method is cash, I want a popup to ask for cash amount and return change, then once that popup is closed, it proceeds
      const cashReceived = prompt('Enter cash amount received');
      if (cashReceived !== null) {
        const change = cashReceived - totalAmount;
        if (change >= 0) {
          alert(`Change to return: $${change.toFixed(2)}`);
          await processOrder(paymentMethod);
        } else {
          toast.error("Insufficient cash received", {
            // ... toast options
          });
        }
      }
    } else {
      await processOrder(paymentMethod);
    }

  };

  /**
 * Processes the order, sends it to the server, and updates the inventory.
 * @param {string} paymentMethod - The payment method used for the order.
 */
  const processOrder = async (paymentMethod) => {
    //const orderData = prepareOrderData(cart);
    // You'll need to fetch this from user input or your app's state
    const drinks = cart.map(item => item.name); // Assuming 'name' holds the name of the drink
    const totalPrice = totalAmount; // You already have this from your state
    try {
      // Process the order
      // ... existing logic for sending the order data
      const orderResponse = await fetch('https://yifang-server-2xpp.onrender.com/create-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerName,
          drinks,
          paymentMethod,
          totalPrice
        })
      });

      if (!orderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const orderResult = await orderResponse.json();
      console.log(orderResult.message);
      // Loop through orderAmountMap to update inventory
      for (const [itemName, usedQuantity] of Object.entries(orderAmountMap)) {
        if (usedQuantity > 0) {
          const newQuantity = await calculateNewInventoryQuantity(itemName, usedQuantity);
          const roundedQuantity = roundToMaxDecimalPlaces(newQuantity, usedQuantity, servingSizeMap[itemName]);
          console.log(`Update inventory for ${itemName} to amount: ${roundedQuantity}`);
          const updateSuccess = await updateInventoryItem(itemName, roundedQuantity);
          if (!updateSuccess) {
            console.error(`Failed to update inventory for ${itemName}`);
            // Handle failed inventory update
          }
        }
      }

      // Handle successful order processing
      // ...
      toast(`Order Successfully Sent!`, toastOptions);
      setCustomerName('');
      setPaymentMethod('');
      clearCart();
    } catch (error) {
      // Handle errors
      // ...
    }
  };

  /**
 * Navigates the user to the login page.
 */
  const navigateToLoginPage = () => {
    navigate('/Login');
  };

  return (
    <MainLayout>
      <div>
        <button className="cash-Back" onClick={navigateToLoginPage}>
          Back
        </button>
      </div>
      <div className='row'>
      <div style={{paddingBottom : '10px'}}>
    {categories.map(category => (
      <button style = {{margin : '5px'}} class="categoryButton" key={category} onClick={() => setSelectedCategory(category)}>
        {category}
      </button>
    ))}
    <button class = "categoryButton"  onClick={() => setSelectedCategory(null)}>Show All</button>
    
  </div>

  <div className='col-lg-8'>
  {isLoading ? (
    'Loading'
  ) : (
    <div className='row'>
      {products
        .filter((product) => selectedCategory === null || product.category === selectedCategory)
        .map((product, key) => {
          const isInStock = productsInStock[product.name];

          return (
            <div
              key={key}
              className='col-lg-4 mb-4'
              tabIndex="0"
              role="button"
              onClick={() => isInStock && addProductToCart(product)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && isInStock) {
                  addProductToCart(product);
                }
              }}
              aria-label={product.name} // Add aria-label to the div for accessibility
            >
              <div
                className={`pos-item px-3 text-center border ${!isInStock ? 'out-of-stock' : ''}`}
                onClick={() => isInStock && addProductToCart(product)}
              >
                <p aria-label=".">{product.name}</p>
                <img src={product.image} className='img-fluid' alt={product.name} />
                <p>${product.price}</p>
                {!isInStock && <p className="out-of-stock-text">Drink out of stock</p>}
              </div>
            </div>
          );
        })}
    </div>
  )}
</div>


        <div className='col-lg-4'>
          <div class="btn-container"><button className='btn btn-outline-danger btn-sm' onClick={() => clearCart()}> Clear Cart</button></div>
          <div style={{ display: "none" }}>
            <ComponentToPrint cart={cart} totalAmount={totalAmount} ref={componentRef} />
          </div>
          <div className='table-responsive bg-dark'>
            <table className='table table-responsive table-dark table-hover'>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Price</td>
                  <td>Toppings</td>
                  <td>Ice</td>
                  <td>Sugar</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {cart ? cart.map((cartProduct, key) => <tr key={key}>

                  <td>{cartProduct.name}</td>
                  <td>{cartProduct.totalAmount}</td>
                  <td>{cartProduct.toppings?.length || 0}</td>
                  <td>{cartProduct.icelevel}</td>
                  <td>{cartProduct.sugarlevel}</td>
                  <td>
                    <div className="btn-container">
                      <button className='btn btn-danger btn-sm' onClick={() => removeProduct(cartProduct)}><i className="bi bi-x-lg"></i></button>
                      <button className='btn btn-success btn-sm' onClick={() => editProduct(cartProduct)}> <i class="bi bi-pencil"></i></button>
                    </div>
                  </td>
                </tr>)
                  : 'No Item in Cart'}
              </tbody>
            </table>
            <h2 className='px-2 text-white'>Total Amount: ${totalAmount}</h2>
          </div>

          <div className='mt-3'>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter customer name"
              className="form-control mb-3"
            />

            <div>
              <button className='btn btn-primary m-1' onClick={() => handlePayment('debit')}>
                Pay with Debit
              </button>
              <button className='btn btn-secondary m-1' onClick={() => handlePayment('credit')}>
                Pay with Credit
              </button>
              <button className='btn btn-success m-1' onClick={() => handlePayment('cash')}>
                Pay with Cash
              </button>
            </div>

          </div>


        </div>
      </div>
      {selectedProduct && (
        <ProductModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onConfirm={onModalConfirm}
          product={selectedProduct}
        />
      )}
    </MainLayout>
  )
}


export default Cashier