import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css'; // Import the CSS file
import WeatherWidget from './weatherwidget';
import logo from './Assets/11rlogo.png'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

/**
 * Component for the login page.
 * @returns {JSX.Element} JSX element representing the Login component.
 */
function Login() {


  const navigate = useNavigate();
  const apiKey = '1fa723272ecc0ef8b81e9d836e15b7ae';
  const [showRoleSelection, setShowRoleSelection] = useState(false);


  /**
   * Callback function triggered on successful Google OAuth login.
   * Displays options to proceed as Manager or Cashier.
   */
  const handleOAuthSuccess = () => {
    // Display options to proceed as Manager or Cashier
    setShowRoleSelection(true);
  };


    /**
   * Handles the user type click event and navigates accordingly.
   * @param {string} userType - The selected user type ('manager', 'cashier', or 'menu').
   */
  const handleUserTypeClick = (userType) => {
    if (userType === "manager") {
      navigate('/managerFrontPage/');
    }
    else if (userType === "cashier") {
      navigate('/cashier/');
    }
    else {
      navigate('/Menu/');
    }

  }

    /**
   * Navigates to the front page.
   */
  const navigateToLoginPage = () => {
    navigate('/');
  };

  return (
    <div>
      <header>
        <nav className="navbar navbar-light login-header-color">
          <div className="container d-flex justify-content-between">
            {/* Empty Div for spacing */}
            <div className="d-flex align-items-center ms-auto" style={{ opacity: 0, pointerEvents: 'none' }}>
              <WeatherWidget apiKey={apiKey} />
            </div>
            {/* YiFang Fruit Tea Image */}
            <Link to="/" className="navbar-brand d-flex justify-content-center ">
              <img
                src={logo}
                alt="YiFang Fruit Tea Logo"
                className="header-logo"
                aria-label="Yi Fang Fruit Tea Logo. Click to navigate to front page"
              />
            </Link>
            {/* Container for Translate Button and Weather Widget */}
            <div className="d-flex align-items-center ms-auto">
              <WeatherWidget apiKey={apiKey} />
            </div>
          </div>
        </nav>
      </header>
  
      <div className="login-container">
        <button className="login-Back-button" onClick={navigateToLoginPage} aria-label="Back to Front Page Button: Click to navigate back to the front page">
          Back to Front Page
        </button>
        <Link to="/" tabindex="-1">
          <img
            src="https://images.squarespace-cdn.com/content/v1/63082a5d7ba9d655b1718056/c456ce98-67a2-49e8-9ac8-315548c0cf7c/black-logo.png"
            alt="YiFang Logo"
            className="logo-img"
          />
        </Link>
        <h2>Welcome to YiFang fruit tea!</h2>
  
        <GoogleOAuthProvider clientId="485622911129-08oe206de31mg2e519vdhh0j7tfddulo.apps.googleusercontent.com">
          <GoogleLogin
            render={(renderProps) => (
              <button onClick={renderProps.onClick} disabled={renderProps.disabled} aria-label="Login with Google Button: Click to log in with Google and proceed">
                Login with Google
              </button>
            )}
            onSuccess={handleOAuthSuccess}
            onFailure={() => {
              console.log('Login Failed');
            }}
          />
        </GoogleOAuthProvider>
  
        {showRoleSelection && (
          <div className="user-type-buttons">
            <button style={{ margin: '5px' }} className="login-navi" onClick={() => handleUserTypeClick('cashier')} aria-label="Proceed as Cashier Button: Click to proceed as a cashier">
              Proceed as Cashier
            </button>
            <button style={{ margin: '5px' }} onClick={() => handleUserTypeClick('manager')} aria-label="Proceed as Manager Button: Click to proceed as a manager">
              Proceed as Manager
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
