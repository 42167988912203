// RestockReport.js
import React, { useEffect, useState } from 'react';

/**
 * Functional component representing the Restock Report page.
 * @component
 * @returns {JSX.Element} The RestockReport component JSX
 */
const RestockReport = () => {
  //variables to hold serving sizes, stock, names of each ingredient
  const [servingSizeMap, setServingSizeMap] = useState({});
  const [ingredientStock, setIngredientStock] = useState([])
  const [ingredientNames, setIngredientNames] = useState([]);
  //an array with all ingredients that are low/out of stock
  const [lowStock, setLowStock] = useState([]);

  /**
   * Fetches ingredient names and serving sizes from the backend.
   * Populates lowStock based on the current stock levels.
   * @async
   * @function
   * @throws {Error} HTTP errors or errors fetching serving sizes
   */
  const fetchIngredientNamesAndServingSizes = async () => {
    try {
      //get serving sizes from backend
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/inventory/serving-sizes');
      if(!response.ok){
        throw new Error(`HTTP ERROR! Status: ${response.status}`);
      }
      //returns json with names and serving sizes
      const data = await response.json();
      const names = Object.keys(data);
      const servingSizes = Object.values(data)
      setIngredientNames(names);
      setServingSizeMap(data);
      
      //for each ingredient, get the quantity currently in stock and put it in a list
      const stockPromises = names.map(async (ingredientName, index) => {
        const quantity = await getIngredientQuantity(ingredientName);
        return {name: ingredientName, quantity, servingSize: servingSizes[index]};
      });

      //once all of the quantities have been added, sets ingredientStock and filters that list to get lowStock
      Promise.all(stockPromises).then((ingredientStockData) => {
        setIngredientStock(ingredientStockData);

        const lowStockIngredients = ingredientStockData
        .filter(item => item.quantity < 15 * item.servingSize)
        .map(item => item.name);

        setLowStock(lowStockIngredients);
      });



    } catch (error) {
      console.error('Error fetching serving sizes: ', error );
    }
  };
  
  /**
   * Fetches the quantity of a specific ingredient from the backend.
   * @async
   * @function
   * @param {string} ingredientName - The name of the ingredient to fetch.
   * @returns {number} The quantity of the ingredient.
   * @throws {Error} HTTP errors or errors fetching ingredient quantity
   */
  const getIngredientQuantity = async (ingredientName) => {
    try {
      const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/inventory/quantity/${encodeURIComponent(ingredientName)}`);
      if(response.status === 404){
        console.log('Nothing found in database for: ', ingredientName);
        return -1;
      }
      else if(!response.ok){
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data.quantity;
    } catch (error) {
      console.error('Error fetching ingredient quantity: ', error);
      return 0;
      
    }
  };

  useEffect(() => {
    fetchIngredientNamesAndServingSizes();
  }, []);




  return (
    <div>
      <h2 className='prevent'>Restock Report</h2>
      <h3>Low / Out of Stock Items: </h3>
      {/* Add any additional content related to the restock report here */}
      <ul>
        {lowStock.map((ingredient) => (
          <li key = {ingredient}>
            {ingredient} :{' '}
            {ingredientStock.find((item) => item.name === ingredient)?.quantity || 0}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RestockReport;
