import React, { useState, useEffect } from 'react';
import './weatherwidget.css';
import clear from './Assets/clear.png';
import cloud from './Assets/cloud.png';
import drizzle from './Assets/drizzle.png';
import rain from './Assets/rain.png';
import snow from './Assets/snow.png';
import wind from './Assets/wind.png';
// import any other weather condition images you have

const weatherImages = {
  'Clear': clear,
  'Clouds': cloud,
  'Drizzle': drizzle,
  'Rain': drizzle,
  'Snow': snow,
  'Wind': wind,
};

/**
 * A weather widget component that displays current weather information based on user's geolocation.
 * It uses OpenWeatherMap API to fetch weather data.
 * 
 * @param {Object} props - Component props.
 * @param {string} props.apiKey - API key for accessing OpenWeatherMap API.
 * @returns {JSX.Element} - A rendered weather widget component.
 */
const WeatherWidget = ({ apiKey }) => {
    const [weather, setWeather] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      navigator.geolocation.getCurrentPosition(async position => {
        try {
          const { latitude, longitude } = position.coords;
          const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=imperial`);
          if (!response.ok) {
            throw new Error('Failed to fetch weather data');
          }
          const data = await response.json();
          console.log("Weather received: ",data);
          setWeather(data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      });
    }, [apiKey]);
  
    let content;
    let weatherImage = cloud; // Default image
  
    if (loading) {
      content = <p className="bold-white-text">...</p>;
    } else if (error) {
      content = <p className="bold-white-text">N/A</p>;
    } else if (weather && weather.weather && weather.weather[0] && weather.main) {
      const weatherCondition = weather.weather[0].main;
      weatherImage = weatherImages[weatherCondition] || cloud;
      content = <p className="bold-white-text">{weather.main.temp}°F</p>;
    } else {
      content = <p className="bold-white-text">N/A</p>;
    }
  
    return (
      <div className='weather'>
        <img 
          src={weatherImage}
          alt="Weather icon"
          className="weather-icon"
        />
        <div className="text-container">{content}</div>
      </div>
    );
  };
  
  export default WeatherWidget;

