import React from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './MainLayout.css'
import WeatherWidget from '../../weatherwidget';
import logo from '../../Assets/11rlogo.png'
function MainLayout({ children }) {
  const apiKey = '1fa723272ecc0ef8b81e9d836e15b7ae';
  return (
    <div>
      <header>
        <nav className="navbar navbar-light custom-bg-color">
          <div className="container d-flex justify-content-between">
  
            {/* Empty Div for spacing */}
            <div className="d-flex align-items-center ms-auto" style={{ opacity: 0, pointerEvents: 'none' }}>
              <WeatherWidget apiKey={apiKey} />
            </div>
  
            {/* YiFang Fruit Tea Image */}
            <Link to="/" className="navbar-brand d-flex justify-content-center ">
              <img
                src={logo}
                alt="YiFang Fruit Tea Logo"
                aria-label="Yi Fang Fruit Tea Logo. Click to navigate to front page"
                className="header-logo"
              />
            </Link>
  
            {/* Container for Translate Button and Weather Widget */}
            <div className="d-flex align-items-center ms-auto">
              <WeatherWidget apiKey={apiKey} />
            </div>
  
          </div>
        </nav>
      </header>
  
      <main>
        <div className='container mt-3'>
          {children}
        </div>
        <ToastContainer />
      </main>
    </div>
  )
  }
  export default MainLayout
