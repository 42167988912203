
import React from 'react';
import { Link } from 'react-router-dom';
import './FrontPage.css';
import MainLayout from './cashierComponents/layouts/MainLayout'


/**
 * Functional component representing the front page of the application.
 * @returns {JSX.Element} JSX element representing the FrontPage component.
 */
const FrontPage = () => {

  return (
    <MainLayout>
      <div className="f_container" tabIndex="0" aria-label="Welcome to Yi Fang Fruit Tea. This is our home navigation page.">
  
        <div className='front-navi'>
          <Link to="/Menu" className="newPageButton" aria-label="Click to view our menu">
            MenuBoard
          </Link>
  
          <Link to="/Login" className="adminLoginButton" aria-label="Click to Navigate to Employee login">
            Admin Login
          </Link>
        </div>
  
        <Link to="/" tabIndex={-1}>
          <img aria-label="Yi Fang Fruit Tea Logo" src="https://images.squarespace-cdn.com/content/v1/63082a5d7ba9d655b1718056/c456ce98-67a2-49e8-9ac8-315548c0cf7c/black-logo.png" 
          alt="YiFang Logo"
          className="f-logo-img"
          />
        </Link>
  
        <div className="f_header">
          <h1 aria-label="Welcome to YiFang Fruit Tea!">Welcome to YiFang Fruit Tea!</h1>
          <p aria-label="Discover a world of delicious boba tea flavors.">Discover a world of delicious boba tea flavors.</p>
        </div>
  
        <div className="buttons" aria-label="Press to Order">
          <Link to="/Customer" className="startOrderButton">
            Start Order
          </Link>
        </div>
  
        <div className="contact" tabIndex="0" aria-label="Contact Info">
          <h2>Contact Us:</h2>
          <p>Visit us at:</p>
          <p tabIndex="0" aria-label="Our address is two eight zero Legacy Drive street number 102. In Plano Texas. Zip code: 75023">280 Legacy Dr #102, Plano, TX 75023</p>
          <p tabIndex="0" aria-label="Our Phone number is 972-805-6250">Phone: (972) 805-6250</p>
          <p tabIndex="0" aria-label="Our Email is info@yifang.com">Email: info@yifang.com</p>
        </div>
  
      </div>
    </MainLayout>
  );
  };
  
  export default FrontPage;
