import React, { useState } from 'react';
import axios from 'axios';
import './ExcessReport.css';

/**
 * Functional component for handling excess report generation.
 * @returns {JSX.Element} JSX element representing the ExcessReport component.
 */
const ExcessReport = () => {
  // State variables
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [reportGenerated, setReportGenerated] = useState(false);
  const [excessReport, setExcessReport] = useState(null);

  /**
   * Handles the change event when a date is modified.
   * Hides the generated report when the date is changed.
   */
  const handleDateChange = () => {
    setReportGenerated(false);
  };

  /**
   * Handles the form submission for generating the excess report.
   * @param {Event} e - The form submission event.
   */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Basic input validation
    if (!startDate || !endDate || !startTime || !endTime) {
      window.alert('Please fill in all date and time.');
      return;
    }

    try {
      setLoading(true);

      // Make a request to the backend endpoint
      const response = await axios.get('https://yifang-server-2xpp.onrender.com/excess-report', {
        params: {
          dateString: startDate,
          timeString: startTime,
        },
      });

      // Log the entire response to the console
      console.log('Response from backend:', response);

      // Check if the response has the expected structure
      if (response.data && response.data.excessReportValuesForGUI) {
        setReportGenerated(true);
        setExcessReport(response.data.excessReportValuesForGUI);
      } else {
        window.alert('Invalid response from the server.');
      }
    } catch (error) {
      console.error('Error fetching excess report:', error);
      window.alert(`Error generating excess report: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className='prevent'>Excess Report</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="form-row">
          <label htmlFor="startDateTime" className="form-label">
            Start Date and Time:
          </label>
          <input
            id="startDateTime"
            className="form-input"           
            type="datetime-local"
            value={startDate + 'T' + startTime}
            onChange={(e) => {
              const dateTime = e.target.value.split('T');
              setStartDate(dateTime[0]);
              setStartTime(dateTime[1]);
              handleDateChange();
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="endDateTime" className="form-label">
            End Date and Time:
          </label>
          <input
            id="endDateTime"
            className="form-input"
            type="datetime-local"
            value={endDate + 'T' + endTime}
            onChange={(e) => {
              const dateTime = e.target.value.split('T');
              setEndDate(dateTime[0]);
              setEndTime(dateTime[1]);
              handleDateChange();
            }}
          />
        </div>
        {loading && <p>Loading...</p>}
        <br />

        {reportGenerated && (
          <div>
            <p>Generated Report:</p>
            <ul>
              {excessReport &&
                Object.entries(excessReport).map(([ingredient, percentage]) => (
                  <li key={ingredient}>{`${ingredient}: ${percentage}`}</li>
                ))}
            </ul>
          </div>
        )}

        <button className="Report-Button" type="submit">
          Generate Report
        </button>
      </form>
    </div>
  );
};

export default ExcessReport;
