import React, { useState, useEffect, memo } from 'react';
import './edit.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import MainLayout from './cashierComponents/layouts/MainLayout'


/**
 * Component for editing menu items.
 * @returns {JSX.Element} JSX element representing the EditMenuItem component.
 */
const EditMenuItem = () => {
  const navigate = useNavigate();


  /**
   * State for managing form data for the menu item.
   * @type {Object}
   * @property {string} drinkName - The name of the drink.
   * @property {string} price - The price of the drink.
   * @property {string[]} ingredients - Array of selected ingredients for the drink.
   * @property {string} sugarLevel - The sugar level of the drink.
   * @property {string} iceLevel - The ice level of the drink.
   */
  const [formData, setFormData] = useState({
    drinkName: '',
    price: '',
    ingredients: '',
    sugarLevel: '',
    iceLevel: '',
  });

  /**
   * State for storing the menu ID.
   * @type {string}
   */
  const [menuId, setMenuId] = useState('');

  /**
   * State for storing the list of drinks.
   * @type {Array}
   */
  const [drinkList, setDrinkList] = useState([]);

  /**
   * State for controlling the visibility of the modal.
   * @type {boolean}
   */
  const [showModal, setShowModal] = useState(false);

  /**
   * State for storing the list of inventory items.
   * @type {Array}
   */
  const [inventoryList, setInventoryList] = useState([]);


  /**
   * Fetches the list of inventory items from the backend.
   */
  const fetchInventoryList = async () => {
    try {
      const response = await fetch('https://yifang-server-2xpp.onrender.com/inventory');
      const inventoryData = await response.json();
      console.log('Inventory Data:', inventoryData);
      setInventoryList(inventoryData.Inventory);
    } catch (error) {
      console.error('Error fetching inventory list:', error);
    }
  };
  
  useEffect(() => {
    fetchInventoryList();
  }, []);

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    setShowModal(false);
  };



  /**
   * Handles the change of input values in the form.
   * @param {Event} e - The input change event.
   */
const handleInputChange = (e) => {
  e.preventDefault();
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};


  /**
   * Handles the change of ingredient checkboxes.
   * @param {Event} e - The checkbox change event.
   */
const handleIngredientCheckboxChange = (e) => {
  const { value } = e.target;
  const updatedIngredients = [...formData.ingredients];

  if (updatedIngredients.includes(value)) {
    // Remove the ingredient if it's already in the list
    const index = updatedIngredients.indexOf(value);
    updatedIngredients.splice(index, 1);
  } else {
    // Add the ingredient if it's not in the list
    updatedIngredients.push(value);
  }

  setFormData({ ...formData, ingredients: updatedIngredients });
};

  /**
   * Handles the form submission to update a menu item.
   * @param {Event} e - The form submission event.
   */
const handleFormSubmit = async (e) => {
  e.preventDefault();
  try {
    // Fetch the list of ingredients from the backend
    const inventoryResponse = await fetch('https://yifang-server-2xpp.onrender.com/inventory');
    const inventoryData = await inventoryResponse.json();
    const inventoryList = inventoryData.Inventory;

    // Check if all selected ingredients are valid
    const invalidIngredients = formData.ingredients.filter(
      (ingredient) => !inventoryList.includes(ingredient.trim())
    );

    if (invalidIngredients.length > 0) {
      // If there are invalid ingredients, show an alert with details and return
      window.alert(`The following ingredients are not in the inventory: ${invalidIngredients.join(', ')}`);
      return;
    }

    const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/menu/${menuId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        drink_name: formData.drinkName,
        price: formData.price,
        ingredients: formData.ingredients, // No need for {}
        sugar_level: formData.sugarLevel,
        ice_level: formData.iceLevel,
      }),
    });

    const data = await response.json();

    if (data.success) {
      window.alert('Menu item updated successfully');
      window.location.reload();
    } else {
      console.error('Failed to update menu item:', data.message);
    }
  } catch (error) {
    console.error('Error updating menu item:', error);
  }
};

  /**
   * Handles add drink to backend
   * @param {Event} e - The form submission event.
   */
const handleAddDrink = async (e) => {
  e.preventDefault();
  try {
    // Fetch the list of ingredients from the backend
    const inventoryResponse = await fetch('https://yifang-server-2xpp.onrender.com/inventory');
    const inventoryData = await inventoryResponse.json();
    const inventoryList = inventoryData.Inventory;

    // Check if all selected ingredients are valid
    const invalidIngredients = formData.ingredients.filter(
      (ingredient) => !inventoryList.includes(ingredient.trim())
    );

    if (invalidIngredients.length > 0) {
      // If there are invalid ingredients, show an alert with details and return
      window.alert(`The following ingredients are not in the inventory: ${invalidIngredients.join(', ')}`);
      return;
    }

    const response = await fetch('https://yifang-server-2xpp.onrender.com/api/menu', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        drink_name: formData.drinkName,
        price: formData.price,
        ingredients: formData.ingredients, // No need for {}
        sugar_level: formData.sugarLevel,
        ice_level: formData.iceLevel,
      }),
    });

    const data = await response.json();

    if (data.success) {
      window.alert('Drink added successfully');
      fetchDrinkList();
      setFormData({
        drinkName: '',
        price: '',
        ingredients: [],
        sugarLevel: '',
        iceLevel: '',
      });
      closeModal(); // Close the modal after adding the drink
    } else {
      console.error('Failed to add drink:', data.message);
    }
  } catch (error) {
    console.error('Error adding drink:', error);
  }
};

  /**
   * Handles the selectable drinks from backned
   */
  const handleSelectDrink = async (selectedMenuId) => {
    setMenuId(selectedMenuId);

    try {
      const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/menu/${selectedMenuId}`);
      const selectedDrink = await response.json();

      setFormData({
        drinkName: selectedDrink.drink_name,
        price: selectedDrink.price,
        ingredients: selectedDrink.ingredients,
        sugarLevel: selectedDrink.sugar_level,
        iceLevel: selectedDrink.ice_level,
      });
    } catch (error) {
      console.error('Error fetching drink information:', error);
    }
  };



  /**
   * Handles the remove item selected
   */
  const handleRemoveItem = async () => {
    try {
      const response = await axios.delete(`https://yifang-server-2xpp.onrender.com/api/menu/${menuId}`);

      if (response.data.success) {
        window.alert('Menu item removed successfully');
        fetchDrinkList();
        setFormData({
          drinkName: '',
          price: '',
          ingredients: '',
          sugarLevel: '',
          iceLevel: '',
        });
      } else {
        console.error('Failed to remove menu item:', response.data.message);
      }
    } catch (error) {
      console.error('Error removing menu item:', error);
    }
  };

  /**
   * Handles navigation to manager front page 
   */
  const navigateToManager = () => {
    navigate('/ManagerFrontPage');
  };

  /**
   * Handles the fetch DrinkList from backend 
   */
  const fetchDrinkList = async () => {
    try {
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/menu');
      const data = await response.json();
      setDrinkList(data);
    } catch (error) {
      console.error('Error fetching drink list:', error);
    }
  };

  useEffect(() => {
    fetchDrinkList();
  }, []);


  /**
   * Memoized component for displaying the modal to confirm the addition of a new drink.
   * @returns {JSX.Element} JSX element representing the AddDrinkModal component.
   */
  const AddDrinkModal = memo(() => {

    return (
      
      <div className={`modal ${showModal ? 'show' : ''}`}>
        <div className="modal-content">
          
          <h2>Confirm New Drink Infomation</h2>
          <div>
            <label>Drink name:</label>
            <input type="text" name="drinkName" value={formData.drinkName} onChange={handleInputChange} readOnly/>
          </div>
          <div>
            <label>Price:</label>
            <input type="text" name="price" value={formData.price} onChange={handleInputChange} readOnly/>
          </div>
          
          <div>
            <label>Ingredients:</label>
            <input type="text" name="ingredients" value={formData.ingredients} onChange={handleInputChange} />
          </div>
          
          <div>
            <label>Sugar Level:</label>
            <input type="text" name="sugarLevel" value={formData.sugarLevel} onChange={handleInputChange} readOnly/>
          </div>
          <div>
            <label>Ice Level:</label>
            <input type="text" name="iceLevel" value={formData.iceLevel} onChange={handleInputChange} readOnly/>
          </div>
          <button class="navi_button" onClick={handleAddDrink}>Confirm</button>
          <button class="navi_button" onClick={closeModal}>Cancle</button>
        </div>
      </div>
    );
  });

  return (
    <MainLayout>
    <div>
      <div className="edit-manager-navigation">
        <button className="navi_button" onClick={navigateToManager}>Manager Navigation</button>
      </div>

      <Link to="/">
        <img src="https://images.squarespace-cdn.com/content/v1/63082a5d7ba9d655b1718056/c456ce98-67a2-49e8-9ac8-315548c0cf7c/black-logo.png"
          alt="YiFang Logo"
          className="logo-img-edit"
        />
      </Link>

      <h2 className="edit-header">Update Menu Item</h2>

      <form onSubmit={handleFormSubmit}>
        <select onChange={(e) => handleSelectDrink(e.target.value)}>
          <option value="">Select a Drink</option>
          {drinkList.map((drink) => (
            <option key={drink.menu_id} value={drink.menu_id}>
              {drink.drink_name}
            </option>
          ))}
        </select>

        <div className="center-info">
          <div>
            <label>Drink name:</label>
            <input type="text" name="drinkName" value={formData.drinkName} onChange={handleInputChange} />
          </div>
          <div>
            <label>Price:</label>
            <input type="text" name="price" value={formData.price} onChange={handleInputChange} />
          </div>
          
          <div>
            <label>Ingredients:</label>
            <div className="ingredient-checkboxes">
              {inventoryList.map((ingredient) => (
                <div key={ingredient} className="ingredient-checkbox">
                  <input className="each-box"
                    type="checkbox"
                    id={ingredient}
                    name="ingredients"
                    value={ingredient}
                    checked={formData.ingredients.includes(ingredient)}
                    onChange={handleIngredientCheckboxChange}
                  />
                  <label htmlFor={ingredient}>{ingredient}</label>
                </div>
              ))}
            </div>
          </div>
          
          <div>
            <label>Sugar Level:</label>
            <input type="text" name="sugarLevel" value={formData.sugarLevel} onChange={handleInputChange} />
          </div>
          <div>
            <label>Ice Level:</label>
            <input type="text" name="iceLevel" value={formData.iceLevel} onChange={handleInputChange} />
          </div>
        </div>

        <div className="edit-buttons">
          <button className="navi_button" type="submit">Update</button>
          <button className="navi_button" type="button" onClick={handleRemoveItem}>Remove</button>
          <button className="navi_button" type="button" onClick={() => setShowModal(true)}>Add Drink</button>
        </div>

      </form>

      {/* Render the modal component */}
      <AddDrinkModal />
    </div>
    </MainLayout>
  );
};

export default EditMenuItem;
