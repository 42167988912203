import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Menu from './Menu';
import Login from './Login';
import Cashier from './Cashier';
import Customer from './customer';
import MyComponent from './frontend';
import FrontPage from './FrontPage';
import EditMenuItem from './EditMenuItem';
import ManagerPageTest from './ManagerTest';
import CustomerCheckoutPage from './customerCheckoutPage';
import ManagerFrontPage from './ManagerFrontPage';
import TrendsPage from './TrendsPage';
import SalesReport from './SalesReport';
import RestockReport from './RestockReport';
import ExcessReport from './ExcessReport';
import PairedItems from './PairedItems';



import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<FrontPage />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Cashier" element={<Cashier />} />
          <Route path="/Customer" element={<Customer />} />
          <Route path="/ManagerFrontPage" element={<ManagerFrontPage />} />
          <Route path="/frontExample" element={<MyComponent />} />
          <Route path="/Edit" element={<EditMenuItem />} />
          <Route path="/TrendsPage" element={<TrendsPage />} />
          <Route path="/CustomerCheckoutPage" element={<CustomerCheckoutPage />} />
          <Route path="/ManagerPageTest" element={<ManagerPageTest />} />
          <Route path="/sales"  element={<SalesReport />} />
          <Route path="/restock"  element={<RestockReport />} />
          <Route path="/excess"  element={<ExcessReport />} />
          <Route path="/paired"  element={<PairedItems />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
