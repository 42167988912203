import React from 'react';
import MenuBoardItem from './MenuBoard';
import { Link } from 'react-router-dom';
import MainLayout from './cashierComponents/layouts/MainLayout'

/**
 * Functional component representing the menu page.
 * @component
 * @returns {JSX.Element} The Menu component JSX
 */
const Menu = () => {
    /**
     * Array of menu items with details.
     * @type {Array<Object>}
     */
    const menuItems = [{
        itemName: "Matcha Latte",
        description: "Delicious and vibrant beverage made by blending finely powdered green tea with steamed milk.",
        price: 6.49,
        image: "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/images/item-d0365f38-7588-4887-b2c8-6c9eed042cf2.jpg?size=large"

    },
    {
        itemName: "Green Tea Latte",
        description: "Freshly brewed green tea, and fresh organic whole milk.",
        price: 5.99,
        image: "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/6/item-400000022658327626_1661296031.png?size=large"

    },
    {
        itemName: "Lychee Fruit Tea",
        description: "Refreshing tea mixed together with real lychee juice and pulp. Topped with a couple pieces of real lychee.",
        price: 6.49,
        image: "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/6/item-400000022433810206_1661227183.png?size=large"

    },
    {
        itemName: "Lemon Green Tea",
        description: "Fresh lemon and our green tea.",
        price: 5.49,
        image: "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/7/item-400000022459733097_1661227715.png?size=large"

    },
    {
        itemName: "Earl Grey Black Tea Latte",
        description: "Earl Grey tea and organic whole milk.",
        price: 6.49,
        image: "https://d2s742iet3d3t1.cloudfront.net/menu_service/restaurants/7ca4f4ee-fa15-4df3-b77d-5e1be815b3c8/MenuItem/1b9e7757-0c1a-44e4-8090-5c1ccb619847.png?size=large"

    },
    {
        itemName: "Sea Salt Cream Lugu Oolong Tea",
        description: "Oolong Tea topped with whipped cream, with a pinch of sea salt.",
        price: 6.49,
        image: "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/images/item-47c26340-940c-4f16-9e8b-5debe15240f5.jpg?size=large"

    },
    {
        itemName: "Traditional Black Milk Tea",
        description: "A classic milk tea made with our Black tea and non dairy creamer, dont forget Boba.",
        price: 5.49,
        image: "https://d2s742iet3d3t1.cloudfront.net/menu_service/restaurants/7ca4f4ee-fa15-4df3-b77d-5e1be815b3c8/MenuItem/a37671b8-5a52-45f4-ae2e-18b3f9185260.png?size=large"

    },
    {
        itemName: "Aiyu Jelly Lemon Tea",
        description: "A super refreshing mix of brewed green tea, freshly squeezed lemon juice, and Aiyu jelly, which is a jelly made of green tea and fig fruit",
        price: 5.99,
        image: "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/2/item-400000022460138002_1661301686.png?size=large"

    }];
    /**
     * Renders the Menu component.
     * @returns {JSX.Element} The JSX for the Menu component
     */
    return (
        <div className="menu" aria-label="Yi Fang Fruit Tea Menu">
      
          <Link to="/" aria-label="Navigate to Home">
            <img src="https://images.squarespace-cdn.com/content/v1/63082a5d7ba9d655b1718056/c456ce98-67a2-49e8-9ac8-315548c0cf7c/black-logo.png"
              alt="YiFang Logo"
              className="logo-img"
            />
          </Link>
      
          {menuItems.map((item, index) => (
            <MenuBoardItem
              key={index}
              itemName={item.itemName}
              description={item.description}
              price={item.price}
              image={item.image}
            />
          ))}
        </div>
      );
};

export default Menu;