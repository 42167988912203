import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './customer.css'; // Create a corresponding CSS file for styling
import Modal from 'react-modal'
import ProductModal from './cashierComponents/ProductModal';
import { v4 as uuidv4 } from 'uuid';
import MainLayout from './cashierComponents/layouts/MainLayout'
import localData from './data/db.json';
import defaultImg from './cashierComponents/default_img.png';
import { reference } from '@popperjs/core';



Modal.setAppElement('#root');

function MenuButtons() {
  const navigate = useNavigate();


  // State to track the selected item and items in the 
  const menuImages = ["https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/images/item-d0365f38-7588-4887-b2c8-6c9eed042cf2.jpg?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/6/item-400000022658327626_1661296031.png?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/6/item-400000022433810206_1661227183.png?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/7/item-400000022459733097_1661227715.png?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/menu_service/restaurants/7ca4f4ee-fa15-4df3-b77d-5e1be815b3c8/MenuItem/1b9e7757-0c1a-44e4-8090-5c1ccb619847.png?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/images/item-47c26340-940c-4f16-9e8b-5debe15240f5.jpg?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/menu_service/restaurants/7ca4f4ee-fa15-4df3-b77d-5e1be815b3c8/MenuItem/a37671b8-5a52-45f4-ae2e-18b3f9185260.png?size=large",
    "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-109801000000000000/menu/items/2/item-400000022460138002_1661301686.png?size=large",
    "https://thegreenloot.com/wp-content/uploads/2019/11/gingerbread-hot-cocoa-3.jpg"];

  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);


  const [isOpen, setOpen] = useState(false);

  const [menuItems, setMenuItems] = useState([]);
  const [menuPrices, setDrinkPrices] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [selectedToppings, setSelectedToppings] = useState([]);

  const [servingSizeMap, setServingSizeMap] = useState({});
  const [orderAmountMap, setOrderAmountMap] = useState({});
  const [originalProduct, setOriginalProduct] = useState(null);
  const [productsInStock, setProductsInStock] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = ["Tea Lattes", "Flavored Teas", "Milk Teas", "Other"];



  /*   useEffect(() => {
      // Fetch drink names
      axios.get('https://yifang-server-2xpp.onrender.com/drinks')
        .then(response => {
          console.log('Response from drinks API:', response.data); // Log the response data
          setDrinkNames(response.data.drinkNames);
        })
        .catch(error => {
          console.error('Error fetching drink names:', error);
        });
    
      // Fetch drink prices
      axios.get('https://yifang-server-2xpp.onrender.com/prices')
        .then(response => {
          console.log('Response from prices API:', response.data); // Log the response data
          setDrinkPrices(response.data.drinkPrices);
        })
        .catch(error => {
          console.error('Error fetching drink prices:', error);
        });
    }, []); */

  useEffect(() => {
    fetchProducts();
    fetchServingSizesAndInitializeOrders();
  }, []);

  useEffect(() => {
    let newTotalAmount = 0;
    cartItems.forEach(cartItem => {
      newTotalAmount += parseFloat(cartItem.totalAmount)
    });
    setTotalAmount(newTotalAmount.toFixed(2));
  }, [cartItems]);

  useEffect(() => {
    // Load cart from localStorage
    const savedCart = localStorage.getItem('CustomerCart');
    if (savedCart) {
      setCartItems(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    const checkAllProductsStock = async () => {
      let stockStatus = {};
      for (const product of menuItems) {
        stockStatus[product.name] = await isEnoughInventoryForProduct(product);
      }
      console.log("Drinks in stock: ", stockStatus);
      setProductsInStock(stockStatus);
    };

    checkAllProductsStock();
  }, [menuItems, orderAmountMap, servingSizeMap]); // Add dependencies as needed

  /**
 * Updates the cart with new items and saves it to local storage.
 * @param {Array} newCart - The new array of items to be set in the cart.
 */
  const updateCart = (newCart) => {
    setCartItems(newCart);
    localStorage.setItem('CustomerCart', JSON.stringify(newCart));
  };

  /**
 * Prepares the selected menu item for editing and opens the modal.
 * @param {Object} menuItem - The menu item to be edited.
 */
  const editProduct = (menuItem) => {
    setSelectedItem({ ...menuItem, isEditing: true });
    setSelectedToppings(menuItem.toppings);
    setModalShow(true);
  };


  /**
 * Opens a modal for a given menu item.
 * @param {Object} menuItem - The menu item for which the modal is to be opened.
 * @param {number} key - The key or identifier for the menu item.
 */
  const openModal = (menuItem, key) => {
    setSelectedItem({ ...menuItem, topping: [], isEditing: false });
    setModalShow(true);
  };

  /**
 * Attempts to add a product to the cart after checking inventory.
 * @param {Object} product - The product to be added.
 * @param {number} key - The key or identifier for the product.
 */
  const addProductToCart = async (product, key) => {
    const enoughInventory = await isEnoughInventoryForProduct(product);
    if (enoughInventory) {
      openModal(product, key);
    }
    else {
      toast.error("Not enough inventory for this product", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  };

  /**
 * Handles the modal confirmation, either editing or adding a product with options.
 * @param {Object} productWithOptions - The product with selected options.
 */
  const onModalConfirm = async (productWithOptions) => {
    let allToppingsAvailable = await isEnoughInventoryForProductTopping(productWithOptions);

    if (allToppingsAvailable) {
      addIngredientAndToppingAmounts(productWithOptions);
      if (productWithOptions.isEditing) {
        editProductInCart(productWithOptions);
      }
      else {
        addProductWithOptionsToCart(productWithOptions);
      }
    }
    else {
      toast.error("Not enough inventory for topping chosen", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }

    setModalShow(false);
  };

  /**
 * Removes the quantities of toppings and ingredients from the order amount map.
 * @param {Object} productWithOptions - The product from which toppings and ingredients are to be removed.
 */
  const removeToppingAndIngredient = (productWithOptions) => {
    const updatedOrderAmounts = { ...orderAmountMap };
    const toppings = productWithOptions.toppings;
    const ingredients = productWithOptions.ingredients;

    toppings.forEach(topping => {
      const servingSize = servingSizeMap[topping] || 0;
      updatedOrderAmounts[topping] = Math.max((updatedOrderAmounts[topping] || 0) - servingSize, 0);
    });

    ingredients.forEach(ingredient => {
      const servingSize = servingSizeMap[ingredient] || 0;
      updatedOrderAmounts[ingredient] = Math.max((updatedOrderAmounts[ingredient] || 0) - servingSize, 0);
    });

    setOrderAmountMap(updatedOrderAmounts);
  }

  /**
 * Adds the quantities of toppings and ingredients to the order amount map.
 * @param {Object} productWithOptions - The product to which toppings and ingredients are to be added.
 */
  const addIngredientAndToppingAmounts = (productWithOptions) => {
    const updatedOrderAmounts = { ...orderAmountMap };
    if (!productWithOptions.isEditing) {
      const ingredients = productWithOptions.ingredients;
      const toppings = productWithOptions.toppings;
      console.log("ingredients being added: ", ingredients);

      ingredients.forEach(ingredient => {
        const servingSize = servingSizeMap[ingredient] || 0;
        updatedOrderAmounts[ingredient] = (updatedOrderAmounts[ingredient] || 0) + servingSize;
      });

      console.log('toppings being added: ', toppings);
      toppings.forEach(topping => {
        const servingSize = servingSizeMap[topping] || 0;
        updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) + servingSize;
      });
    }
    else {
      const originalToppings = originalProduct.toppings;
      const newToppings = productWithOptions.toppings;

      originalToppings.forEach(topping => {
        if (!newToppings.includes(topping)) {
          const servingSize = servingSizeMap[topping] || 0;
          updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) - servingSize;
        }
      });

      newToppings.forEach(topping => {
        if (!originalToppings.includes(topping)) {
          const servingSize = servingSizeMap[topping] || 0;
          updatedOrderAmounts[topping] = (updatedOrderAmounts[topping] || 0) + servingSize;
        }
      });
      setOriginalProduct(null);
    }
    setOrderAmountMap(updatedOrderAmounts);
  };

  /**
 * Edits an existing product in the cart.
 * @param {Object} product - The product to be edited.
 */
  const editProductInCart = (product) => {
    const existingProductIndex = cartItems.findIndex((cartItem) => cartItem.cartItemID === product.cartItemID);

    if (existingProductIndex > -1) {
      const newCart = [...cartItems];
      const existingProduct = newCart[existingProductIndex];

      const newTotal = (parseFloat(product.price) + (product.toppings.length * 0.75)).toFixed(2);

      newCart[existingProductIndex] = {
        ...existingProduct,
        totalAmount: newTotal,
        toppings: product.toppings,
        icelevel: product.icelevel,
        sugarlevel: product.sugarlevel
      };

      updateCart(newCart);
    }
    else {
      console.error('product to edit not found in cart');
    }
  };

  /**
 * Determines the number of decimal places in a number.
 * @param {number} num - The number to check.
 * @returns {number} - Number of decimal places.
 */
  const getDecimalPlaces = (num) => {
    if (!isNaN(num) && Math.floor(num) !== num) {
      return num.toString().split(".")[1].length;
    }
    return 0;
  };

  /**
 * Rounds a number to the maximum decimal places of two reference numbers.
 * @param {number} numToRound - The number to round.
 * @param {number} referenceNum1 - The first reference number.
 * @param {number} referenceNum2 - The second reference number.
 * @returns {number} - The rounded number.
 */
  const roundToMaxDecimalPlaces = (numToRound, referenceNum1, referenceNum2) => {
    const decimalPlaces1 = getDecimalPlaces(referenceNum1);
    const decimalPlaces2 = getDecimalPlaces(referenceNum2);
    const maxDecimalPlaces = Math.max(decimalPlaces1, decimalPlaces2);

    return parseFloat(numToRound.toFixed(maxDecimalPlaces));

  };

  /**
 * Updates the quantity of an inventory item in the database.
 * @param {string} itemName - The name of the item to update.
 * @param {number} newQuantity - The new quantity to set.
 * @returns {Promise<boolean>} - True if update is successful, false otherwise.
 */
  const updateInventoryItem = async (itemName, newQuantity) => {
    try {
      const url = 'https://yifang-server-2xpp.onrender.com/ManagerPageTest_Update/${encodeURIComponent(itemName)}/${newQuantity}';
      console.log('Updating inventory with URL: ${url}');

      const response = await axios.put(url);

      if (response.data.success) {
        return true;
      } else {
        throw new Error(`API responded with success: false for ${itemName}`);
      }
    } catch (error) {
      console.error(`Error updating inventory for ${itemName}:`, error);
      return false;
    }
  };

  /**
 * Calculates the new inventory quantity for an item after usage.
 * @param {string} itemName - The name of the inventory item.
 * @param {number} usedQuantity - The quantity of the item used.
 * @returns {Promise<number>} - The new inventory quantity after deduction.
 */
  const calculateNewInventoryQuantity = async (itemName, usedQuantity) => {
    // Fetch current quantity from inventory
    // Implement fetching current inventory quantity logic here
    const currentQuantity = await getIngredientQuantity(itemName);
    return Math.max(currentQuantity - usedQuantity, 0); // Ensure the quantity doesn't go below 0
  };

  /**
 * Adds a product with options (like toppings) to the cart.
 * @param {Object} item - The item to be added to the cart.
 */
  const addProductWithOptionsToCart = (item) => {
    const newItem = {
      ...item,
      cartItemId: uuidv4(),
    };
    updateCart([...cartItems, newItem]);
  };

  /**
 * Closes the modal window.
 */
  const closeModal = () => {
    setModalShow(false);
  };

  /**
 * Checks if there is enough inventory for all ingredients of a product.
 * @param {Object} product - The product to check inventory for.
 * @returns {Promise<boolean>} - True if enough inventory is available, false otherwise.
 */
  const isEnoughInventoryForProduct = async (product) => {
    for (const ingredient of product.ingredients) {
      const requiredQuantity = (orderAmountMap[ingredient] || 0) + (servingSizeMap[ingredient] || 0);
      const availableQuantity = await getIngredientQuantity(ingredient);

      if (requiredQuantity > availableQuantity) {
        return false;
      }
    }
    return true;
  };

  /**
 * Checks if there is enough inventory for all toppings of a product.
 * @param {Object} productWithOptions - Product with options such as toppings.
 * @returns {Promise<boolean>} - True if enough inventory, false otherwise.
 */
  const isEnoughInventoryForProductTopping = async (productWithOptions) => {
    for (const topping of productWithOptions.toppings) {
      let requiredQuantity = (orderAmountMap[topping] || 0) + (servingSizeMap[topping] || 0);

      if (productWithOptions.isEditing) {
        const originalToppings = originalProduct.toppings;

        if (originalToppings.includes(topping)) {
          requiredQuantity -= servingSizeMap[topping];
        }
      }
      const availableQuantity = await getIngredientQuantity(topping);

      if (requiredQuantity > availableQuantity) {
        return false;
      }
    }
    return true;
  };

  /**
 * Fetches the quantity of a specific ingredient from the inventory.
 * @param {string} ingredientName - Name of the ingredient.
 * @returns {Promise<number>} - The quantity of the ingredient.
 */
  const getIngredientQuantity = async (ingredientName) => {
    try {
      console.log("ingredient name: ", ingredientName);
      const response = await fetch(`https://yifang-server-2xpp.onrender.com/api/inventory/quantity/${encodeURIComponent(ingredientName)}`);
      if (response.status === 404) {
        console.log("Nothing found in database for: ", ingredientName);
        return -1;
      }
      else if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`Quantity of ${ingredientName}: ${data.quantity}`);

      return data.quantity;
    } catch (error) {
      console.error('Error fetching ingredient quantity: ', error);
      return 0;
    }
  };

  /**
 * Fetches products from a remote server and processes them.
 */
  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/products');
      const data = await response.json();
      if (data && data.products) {
        mergeProductData(data.products);
      } else {
        console.error('API response is missing the products field:', data);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setIsLoading(false);
  };

  /**
 * Merges product data from an API with local product data.
 * @param {Array} apiProducts - Products fetched from an API.
 */
  const mergeProductData = (apiProducts) => {
    const mergedProducts = apiProducts.map(apiProduct => {
      const localProduct = localData.products.find(p => p.name === apiProduct.name);
      if (localProduct) {
        return { ...apiProduct, ...localProduct };
      } else {
        return {
          ...apiProduct,
          description: "No description available", // Default description
          image: defaultImg, // Default image
          category: "Other"
        };
      }
    });
    console.log("Merged from DB: ", mergedProducts)
    setMenuItems(mergedProducts);
  };

  /**
 * Fetches serving sizes and initializes the order amount map.
 */
  const fetchServingSizesAndInitializeOrders = async () => {
    try {
      const response = await fetch('https://yifang-server-2xpp.onrender.com/api/inventory/serving-sizes');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setServingSizeMap(data);

      const initialOrderAmounts = Object.keys(data).reduce((acc, ingredient) => {
        acc[ingredient] = 0;
        return acc;
      }, {});

      setOrderAmountMap(initialOrderAmounts);
    } catch (error) {
      console.error('Error fetching serving sizes: ', error);
    }
  };


  /**
 * Navigates the user to the checkout page.
 */
  const navigateToCheckout = () => {
    navigate('/customerCheckoutPage');
  };

  /**
 * Navigates the user to the home page.
 */
  const navigateToHomePage = () => {
    navigate('/');
  };

  return (
    <MainLayout>
      <div className='container'>
        <div className='row justify-content-center'>
          <div style={{ paddingBottom: '10px' }}>
            {categories.map((category, index) => (
              <button
                key={index}
                style={{ margin: '5px' }}
                className="categoryButton"
                onClick={() => setSelectedCategory(category)}
                tabIndex="0"
              >
                {category}
              </button>
            ))}
            <button
              className="categoryButton"
              onClick={() => setSelectedCategory(null)}
              tabIndex="0"
            >
              Show All
            </button>
          </div>
          <div className='col-lg-8'>
            {isLoading ? (
              'Loading'
            ) : (
              <div className='row'>
                {menuItems.filter((product) => selectedCategory === null || product.category === selectedCategory).map((product, key) => {
                  const isInStock = productsInStock[product.name];
  
                  return (
                    <div
                      key={key}
                      className='col-lg-4 mb-4'
                      tabIndex="0"
                      role="button"
                      onClick={() => isInStock && addProductToCart(product)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && isInStock) {
                          addProductToCart(product);
                        }
                      }}
                    >
                      <div
                        className={`pos-item px-3 text-center border ${!isInStock ? 'out-of-stock' : ''}`}
                        onClick={() => isInStock && addProductToCart(product)}
                      >
                        <p aria-label=".">{product.name}</p>
                        <img src={product.image} className='img-fluid' alt={product.name} />
                        <p>${product.price}</p>
                        {!isInStock && <p className="out-of-stock-text">Drink out of stock</p>}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
  
      {selectedItem && (
        <ProductModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onConfirm={onModalConfirm}
          product={selectedItem}
          description={selectedItem.description}
        />
      )}
  
      <div>
        {cartItems.length > 0 && (
          <button
            className="check-out-button"
            aria-label="Review Order: Click to proceed to checkout"
            onClick={navigateToCheckout}
          >
            Review Order
          </button>
        )}
        <button
          className="cust-Back"
          aria-label="Back Button: Click to travel to front page"
          tabIndex="0"
          onClick={navigateToHomePage}
        >
          Back
        </button>
      </div>
    </MainLayout>
  );
}

export default MenuButtons;